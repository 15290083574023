import { defineStore } from 'pinia';
import { Http } from '../../ts-modules/http-client-module';
import { WebStorage } from '../../ts-modules/storage-module';
import { copyWithoutReference } from '../../ts-modules/utils-module/utils/copy-utils';
import { IsHateoasResponse } from '../interfaces/hateoas/is-hateoas-response';
import { useApiStore } from './api-store';
import { PiniaSingleton } from '../singletons/pinia-singleton';
import { IsReceiving } from '../interfaces/receivings/is-receiving';

interface isOrderState {
	apiEndpoint: URL;
	defaultState: IsHateoasResponse;
	currentState: IsHateoasResponse;
	selectedReceiving: IsReceiving | undefined;
}

const storeId: string = 'receivings';
const defaultId: string = '_default';
const selectedId: string = '_selected';

const pinia = PiniaSingleton.getInstance();
const apiStore = useApiStore(pinia);
const api = apiStore.get;

export const useReceivingsStore = defineStore(storeId, {
	state: (): isOrderState => ({
		apiEndpoint: new URL(`${api}/${import.meta.env.VITE_RECEIVINGS_ENDPOINT}`),
		defaultState: WebStorage.Default.getFromStorage(storeId + defaultId),
		currentState: WebStorage.Default.getFromStorage(storeId),
		selectedReceiving: WebStorage.Default.getFromStorage(storeId + selectedId)
	}),
	getters: {
		getReceivings(state): IsReceiving[] {
			if (this.currentState) {
				const embedded = state.currentState._embedded;
				if (embedded) {
					return embedded.receivings ? embedded.receivings : [];
				}
			}
			return [];
		},
		getSelectedReceiving(state): IsReceiving | undefined {
			if (state.selectedReceiving) {
				return state.selectedReceiving;
			} else {
				return undefined;
			}
		},
		getOriginals(state): IsReceiving[] {
			if (this.defaultState) {
				const embedded = state.defaultState._embedded;
				if (embedded) {
					return embedded.receivings ? embedded.receivings : [];
				}
			}
			return [];
		}
	},
	actions: {
		setReceivings(receivings: IsReceiving[]): void {
			if (this.currentState) {
				const embedded = this.currentState._embedded;
				if (embedded) {
					embedded.receivings = [...receivings];
				}
			}
		},
		setSelectedReceiving(receiving: IsReceiving | undefined): void {
			this.selectedReceiving = receiving;
		},
		async resetReceiving(): Promise<void> {
			return new Promise<void>(async resolve => {
				await this.emptyState();
				await this.downloadReceivings();
				await this.cacheState();
				resolve();
			});
		},
		async downloadReceivings() {
			const { body } = await Http.get(this.apiEndpoint);
			this.currentState = body;
			this.defaultState = copyWithoutReference(body);
		},
		async uploadReceivingsChanges() {
			if (this.currentState._embedded) {
				await Http.put(this.apiEndpoint, this.currentState._embedded.receivings);
			}
		},
		async cacheState() {
			WebStorage.Default.saveToStorage(storeId, this.currentState);
			WebStorage.Default.saveToStorage(storeId + defaultId, this.currentState);
			if (this.selectedReceiving) {
				WebStorage.Default.saveToStorage(storeId + selectedId, this.selectedReceiving);
			}
		},
		async emptyState() {
			this.currentState = {};
			WebStorage.Default.removeFromStorage(storeId);
		}
	}
});
