import { IsBulkLine } from './bulk-lines';
import { IsOrder } from '../orders/is-order';

export interface IsBulk {
	bulkLines: IsBulkLine[];
	createDate: Date;
	orderModels: IsOrder[];
	status: BulkStatus;
	size: number;
	uuid: string;
	packaging: string;
}

export enum BulkStatus {
	PENDING = 'PENDING',
	PROCESSING = 'OPEN',
	FAILED = 'FAILED',
	COMPLETED_WITH_ERRORS = 'COMPLETED_WITH_ERRORS',
	COMPLETED = 'COMPLETED',
	PRINTED = 'PRINTED'
}
