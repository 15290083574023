<script setup lang="ts">
import { Slideover } from '../../base-components/Headless';
import Lucide from '../../base-components/Lucide';
import Button from '../../base-components/Button';
import BulkHistoryList from '../BulkHistoryList/BulkHistoryList.vue';
import { IsFilterOption } from '../Filter/interfaces/is-filter-option';
import { ref } from 'vue';
import { bulkHistoryFilterSettings } from '../../constants/filters';
import Filter from '../Filter';

// Properties
const props = defineProps(['modelValue', 'bulkHistory']);
const emit = defineEmits(['update:modelValue']);

// References
const bulkHistoryFilter = ref(bulkHistoryFilterSettings);

// Functions
function filterOn(option: IsFilterOption): void {
	console.log(option);
}
</script>

<template>
	<!-- BEGIN: Modal Toggle -->
	<div class="text-center"></div>
	<!-- END: Modal Toggle -->
	<!-- BEGIN: Modal Content -->
	<Slideover size="xl" backdrop="static" :open="modelValue" @close="$emit('update:modelValue', false)">
		<Slideover.Panel>
			<div @click="$emit('update:modelValue', false)" class="absolute top-0 left-0 right-auto mt-4 -ml-12 cursor-pointer">
				<Lucide icon="X" class="w-8 h-8 text-slate-400" />
			</div>
			<Slideover.Title class="p-5">
				<h2 class="mr-auto text-base font-medium">{{ $t('bulkHistory.title') }}</h2>
			</Slideover.Title>
			<Slideover.Description>
				<div>
					<h2 class="text-lg font-medium p-2 pl-0">{{ $t('bulkHistory.filterTitle') }}</h2>
				</div>
				<Filter :filter="bulkHistoryFilter" @filter-changed="filterOn($event)" />

				<div>
					<h2 class="text-lg font-medium p-2 pl-0">{{ $t('bulkHistory.listTitle') }}</h2>
				</div>
				<bulk-history-list :bulk-history="props.bulkHistory"></bulk-history-list>
			</Slideover.Description>
			<Slideover.Footer>
				<Button variant="outline-secondary" type="button" @click="$emit('update:modelValue', false)" class="w-20 mr-1"> Cancel </Button>
				<Button variant="primary" type="button" class="w-20"> Send</Button>
			</Slideover.Footer>
		</Slideover.Panel>
	</Slideover>
	<!-- END: Modal Content -->
</template>
