import { defineStore } from 'pinia';

interface isBulkHistorySlideOverState {
	visible: boolean;
}

const storeId: string = 'FootprintDetailsSlideOverStore';
export const useBulkHistorySlideOverStore = defineStore(storeId, {
	state: (): isBulkHistorySlideOverState => ({
		visible: false
	}),
	getters: {
		get(state): isBulkHistorySlideOverState {
			return state;
		}
	},
	actions: {
		setVisibility(visible: boolean): void {
			this.visible = visible;
		}
	}
});
