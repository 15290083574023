// @ts-nocheck
import { createApp, watch } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/app.css';
import i18n from './i18n';
import { useAuthStore } from './stores/auth-store';
import { AxiosHttpClient } from '../ts-modules/http-client-module';
import { PiniaSingleton } from './singletons/pinia-singleton';

// Pina
const pinia = PiniaSingleton.getInstance();

// Authentication
let authenticated = false;
const authStore = useAuthStore(pinia);
authStore.loginRedirect().then();

// Http interceptors
const authInterceptor = async request => {
	const accessToken = await authStore.getAccessToken();

	// Add Bearer token to request
	const bearer = 'Bearer ' + accessToken;
	request.headers['Authorization'] = bearer;

	// Continue
	return request;
};
AxiosHttpClient.useInterceptor(authInterceptor);

// Mount app when user is authenticated
authenticated = !!authStore.homeId;
if (authenticated) {
	createApp(App).use(router).use(i18n).use(pinia).mount('#app');
} else {
	// Listen for auth changes
	const unWatch = watch(
		authStore,
		state => {
			authenticated = !!authStore.homeId;
			// Mount app when user is authenticated
			if (authenticated) {
				createApp(App).use(router).use(i18n).use(pinia).mount('#app');
				unWatch();
			}
		},
		{ deep: true }
	);
}
