import { defineStore } from 'pinia';
import { Http } from '../../ts-modules/http-client-module';
import { WebStorage } from '../../ts-modules/storage-module';
import { copyWithoutReference } from '../../ts-modules/utils-module/utils/copy-utils';
import { IsOrder } from '../interfaces/orders/is-order';
import { IsHateoasResponse } from '../interfaces/hateoas/is-hateoas-response';
import { useApiStore } from './api-store';
import { PiniaSingleton } from '../singletons/pinia-singleton';
import { IsNote } from '../interfaces/orders/is-note';

interface isOrderState {
	apiEndpoint: URL;
	defaultState: IsHateoasResponse;
	currentState: IsHateoasResponse;
	selectedOrder: IsOrder | undefined;
}

const storeId: string = 'orders';
const defaultId: string = '_default';
const selectedId: string = '_selected';

const pinia = PiniaSingleton.getInstance();
const apiStore = useApiStore(pinia);
const api = apiStore.get;

export const useOrdersStore = defineStore(storeId, {
	state: (): isOrderState => ({
		apiEndpoint: new URL(`${api}/${import.meta.env.VITE_ORDERS_ENDPOINT}`),
		defaultState: WebStorage.Default.getFromStorage(storeId + defaultId),
		currentState: WebStorage.Default.getFromStorage(storeId),
		selectedOrder: WebStorage.Default.getFromStorage(storeId + selectedId)
	}),
	getters: {
		getOrders(state): IsOrder[] {
			if (this.currentState) {
				const embedded = state.currentState._embedded;
				if (embedded) {
					return embedded.orderModelList ? embedded.orderModelList : [];
				}
			}
			return [];
		},
		getSelectedOrder(state): IsOrder | undefined {
			if (state.selectedOrder) {
				return state.selectedOrder;
			} else {
				return undefined;
			}
		},
		getOriginals(state): IsOrder[] {
			if (this.defaultState) {
				const embedded = state.defaultState._embedded;
				if (embedded) {
					return embedded.orderModelList ? embedded.orderModelList : [];
				}
			}
			return [];
		}
	},
	actions: {
		setOrders(orders: IsOrder[]): void {
			if (this.currentState) {
				const embedded = this.currentState._embedded;
				if (embedded) {
					embedded.orderModelList = [...orders];
				}
			}
		},
		setSelectedOrder(order: IsOrder | undefined): void {
			this.selectedOrder = order;
		},
		async resetOrders(): Promise<void> {
			return new Promise<void>(async resolve => {
				await this.emptyState();
				await this.downloadOrders();
				await this.cacheState();
				resolve();
			});
		},
		async downloadOrders() {
			const { body } = await Http.get(this.apiEndpoint);
			this.currentState = body;
			this.defaultState = copyWithoutReference(body);
		},
		async downloadOrder(orderNumber: string): Promise<IsOrder> {
			return new Promise<IsOrder>(async (resolve, reject) => {
				const url = new URL(`orders/${orderNumber}`, this.apiEndpoint.href);
				Http.get(url).then(
					response => {
						resolve(response.body);
					},
					error => {
						reject(error);
					}
				);
			});
		},
		async downloadNotes(orderNumber: string): Promise<IsNote[]> {
			return new Promise<IsNote[]>(async (resolve, reject) => {
				const url = new URL(`orders/${orderNumber}/note`, this.apiEndpoint.href);
				Http.get(url).then(
					response => {
						resolve(response.body);
					},
					() => {
						resolve([]);
					}
				);
			});
		},
		async uploadOrderChanges() {
			if (this.currentState._embedded) {
				await Http.put(this.apiEndpoint, this.currentState._embedded.orderModelList);
			}
		},
		uploadOrderInEditMode(order: IsOrder): Promise<any> {
			const url = new URL(`${order.orderNumber}`, this.apiEndpoint.href);
			return Http.patch(url, {});
		},
		uploadOrderInDoneMode(order: IsOrder): Promise<any> {
			const url = new URL(`${order.orderNumber}/done`, this.apiEndpoint.href);
			return Http.patch(url, {});
		},
		uploadNewNote(note: string, orderNumber: string) {
			const url = new URL(`orders/${orderNumber}/note`, this.apiEndpoint.href);
			return Http.post(url, { note });
		},
		async uploadOrder(order: IsOrder) {
			const url = new URL(`${order.orderNumber}`, this.apiEndpoint.href);
			return Http.put(url, order);
		},
		async cacheState() {
			WebStorage.Default.saveToStorage(storeId, this.currentState);
			WebStorage.Default.saveToStorage(storeId + defaultId, this.currentState);
			if (this.selectedOrder) {
				WebStorage.Default.saveToStorage(storeId + selectedId, this.selectedOrder);
			}
		},
		async emptyState() {
			this.currentState = {};
			WebStorage.Default.removeFromStorage(storeId);
		}
	}
});
