<script setup lang="ts">
import { ref, watch } from 'vue';
import Sort from '../Sort';
import { useProductsStore } from '../../stores/products-store';
import { IsProduct } from '../../interfaces/Products/is-product';
import { copyWithoutReference } from '../../../ts-modules/utils-module/utils/copy-utils';
import { NumberUtils } from '../../../ts-modules/utils-module';

// Stores
const productStore = useProductsStore();

// References
let products = ref(productStore.getProducts);
let unSortedProducts = ref(products.value);
const listKey = ref('productList');

const props = defineProps<{ filterUpdated: IsProduct[] }>();
const emit = defineEmits(['clicked']);

watch(
	() => props.filterUpdated,
	filteredData => {
		products.value = filteredData || [];
		console.log('Filtered products:', filteredData);
	},
	{ deep: true }
);

if (products.value.length < 1) {
	// Get products
	const productsFromServer = copyWithoutReference(await productStore.getFresh());
	// Save states
	productStore.cache();
	// Show products
	products = ref(productsFromServer);
	// Save unsorted products
	unSortedProducts.value = products.value;
}

function isEven(number: number): boolean {
	return NumberUtils.isEven(number);
}

function productClicked(product: IsProduct): void {
	emit('clicked', product);
}
</script>

<template>
	<div class="box break-words absolute h-full w-full">
		<!-- BEGIN: Table head -->
		<div class="grid grid-cols-1 xl:grid-cols-5 p-3 pl-5 font-medium bg-slate-300 dark:bg-slate-900 rounded-t z-50 top-0">
			<div class="xl:hidden">
				{{ $t('productList.mobileTitle') }}
			</div>
			<div class="hidden xl:block">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'pronkId'"
					:unsorted-array="unSortedProducts"
					@sorted="products = $event"
				></Sort>
			</div>
			<div class="hidden xl:block">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'name'"
					:unsorted-array="unSortedProducts"
					@sorted="products = $event"
				></Sort>
			</div>
			<div class="hidden xl:block">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'physicality'"
					:unsorted-array="unSortedProducts"
					@sorted="products = $event"
				></Sort>
			</div>
			<div class="hidden xl:block">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'stockType'"
					:unsorted-array="unSortedProducts"
					@sorted="products = $event"
				></Sort>
			</div>
			<div class="hidden xl:block">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'unitType'"
					:unsorted-array="unSortedProducts"
					@sorted="products = $event"
				></Sort>
			</div>
		</div>
		<!-- END: Table head -->

		<!-- BEGIN: Table content -->
		<div
			class="grid grid-cols-2 xl:grid-cols-5 p-3 pl-5 cursor-pointer"
			:class="[
				{ 'bg-white hover:bg-slate-300 dark:bg-slate-700 dark:hover:bg-slate-600': !isEven(index) },
				{ 'bg-secondary hover:bg-slate-300 dark:bg-slate-800 dark:hover:bg-slate-600': isEven(index) },
				{ 'rounded-b': products.length - 1 === index }
			]"
			v-for="(product, index) in products"
			:key="product.pronkId"
			@click="productClicked(product)"
		>
			<!-- BEGIN: Column 1 -->
			<div class="xl:pl-3 pb-2 xl:pb-0 relative z-0 col-span-2 sm:col-span-1">
				<div class="flex flex-row relative xl:-left-3 z-0">
					<span class="xl:hidden">{{ $t('productList.pronkId') }}:</span> {{ product.pronkId }}
				</div>
			</div>
			<!-- END: Column 1 -->

			<!-- BEGIN: Column 2 -->
			<div class="pb-2 xl:pb-1 col-span-4 sm:col-span-1">
				<span class="xl:hidden">{{ $t('productList.productName') }}:</span>
				{{ product.name }}
			</div>
			<!-- END: Column 2 -->

			<!-- BEGIN: Column 3 -->
			<div class="pb-2 pt-1 xl:pb-1 col-span-2 sm:col-span-1">
				<span class="xl:hidden">{{ $t('productList.physicality') }}:</span>
				{{ product.physicality }}
			</div>
			<!-- END: Column 3 -->

			<!-- BEGIN: Column 4 -->
			<div class="pt-1 pb-1 col-span-2 sm:col-span-1">
				<span class="xl:hidden">{{ $t('productList.stockType') }}:</span> {{ product.stockType }}
			</div>
			<!-- End: Column 4 -->

			<!-- BEGIN: Column 5 -->
			<div class="pt-1 pb-1 col-span-2 sm:col-span-1">
				<span class="xl:hidden">{{ $t('productList.unitType') }}:</span> {{ product.unitType }}
			</div>
			<!-- End: Column 5 -->
		</div>
		<!-- END: Table content -->
	</div>
</template>

<style scoped>
/* Disable number input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Disable number input arrows for Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
</style>
