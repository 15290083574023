<script setup lang="ts">
import { IsNote } from '../../interfaces/orders/is-note';
import Tippy from '../../base-components/Tippy';
import { InputGroup, FormInput } from '../../base-components/Form';
import Lucide from '../../base-components/Lucide';
import { computed, ComputedRef, ref } from 'vue';
import { DateUtils, NumberUtils } from '../../../ts-modules/utils-module';

// Properties
const props = defineProps<{ notes: IsNote[] }>();
const emits = defineEmits(['addNote']);

// References
const noteInput = ref('');

// Computes
const notesLength: ComputedRef<number> = computed(() => {
	if (props.notes) {
		return props.notes.length;
	} else {
		return 0;
	}
});

const notes: ComputedRef<IsNote[]> = computed(() => {
	if (props.notes) {
		let reversedNotes = [...props.notes].reverse();
		return reversedNotes;
	} else {
		return [];
	}
});

// Init logic

// Functions
function isEven(number: number): boolean {
	return NumberUtils.isEven(number);
}

function getPrettyDate(date: Date): string {
	return DateUtils.dateToString(date);
}

function addNote(note: string): void {
	if (note) {
		emits('addNote', note);
		noteInput.value = '';
	}
}
</script>

<template>
	<h3 class="text-base font-medium">{{ $t('orderDetails.notes') }}</h3>
	<div class="pt-4">
		<!-- BEGIN: Note list	-->
		<div v-if="notesLength < 1">
			{{ $t('orderDetails.noNotes') }}
		</div>
		<div class="-intro-y">
			<div
				v-for="(note, index) in notes"
				:key="`note-${index}`"
				class="p-3 pl-5 divide-y divide-slate-200"
				:class="[
					{ 'bg-white dark:bg-slate-700': !isEven(index) },
					{ 'bg-secondary dark:bg-slate-800': isEven(index) },
					{ 'rounded-t': index === 0 },
					{ 'rounded-b': notesLength - 1 === index }
				]"
			>
				<div>
					<div class="float-right pl-2 pb-2 underline">
						<Tippy variant="primary" :content="`${$t('orderDetails.noteCreator')}: ${note.issuedBy}`">
							{{ getPrettyDate(note.issueDate) }}
						</Tippy>
					</div>
					<p class="note-text">#{{ index + 1 }} {{ note.note }}</p>
				</div>
			</div>
		</div>
		<!-- END: Note list	-->
		<div class="border-t-2 mt-2 mb-2" />

		<!-- BEGIN: Add note	-->
		<div class="mt-2">
			<InputGroup>
				<FormInput
					v-model="noteInput"
					type="text"
					:placeholder="$t('orderDetails.addNote')"
					aria-label="Price"
					aria-describedby="input-group-price"
					@on-enter="addNote($event)"
				/>
				<InputGroup.Text id="input-group-price">
					<Lucide icon="Plus" class="cursor-pointer" @click="addNote(noteInput)" />
				</InputGroup.Text>
			</InputGroup>
		</div>
		<!-- END: Add note	-->
	</div>
</template>

<style scoped></style>
