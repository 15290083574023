<script setup lang="ts" async>
import PageTitle from '../../components/PageTitle/PageTitle.vue';
import router from '../../router';
import { useSideMenuStore } from '../../stores/side-menu';

// Stores
const sideMenuStore = useSideMenuStore();

// init logic
disableBoxedContentStyle(true);

// functions
function disableBoxedContentStyle(disable: boolean): void {
	sideMenuStore.boxedContent = !disable;
	if (disable) {
		// Re-enable boxed content style when leaving page
		router.beforeEach(() => {
			sideMenuStore.boxedContent = true;
		});
	}
}
</script>

<template>
	<div class="flex flex-row">
		<div class="grow -intro-x">
			<PageTitle>{{ $t('addProduct.title') }}</PageTitle>
		</div>
	</div>
	<div class="flex-wrap xl:flex mt-5">
		<div class="xl:w-8/12">
			<div class="full-height">
				<div class="intro-y mb-5">
					<h2 class="text-lg font-medium font-small p-2 pl-0">{{ $t('addProduct.baseAttributesTitel') }}</h2>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped></style>
