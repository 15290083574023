<script setup lang="ts">
import { FormInput, FormLabel } from '../../base-components/Form';
import Litepicker from '../../base-components/Litepicker';
import Lucide from '../../base-components/Lucide';
import { ref } from 'vue';
import { IsReceiving } from '../../interfaces/receivings/is-receiving';
import { dateToString } from '../../../ts-modules/utils-module/utils/date-utils';

// Properties
const props = defineProps<{ modelValue: IsReceiving; disabled?: boolean }>();
const emits = defineEmits(['receivingChanged']);

// References
const receiving = ref<IsReceiving>(props.modelValue);
const pickerOrderDate = ref(new Date(Date.parse(receiving.value.orderDate)));
const pickerOrderDateString = ref(dateToString(pickerOrderDate.value));

const pickerDeliveryDate = ref(new Date(receiving.value.deliveryDate));
const pickerDeliveryDateString = ref(dateToString(pickerDeliveryDate.value));

function setOrderDate(date: string) {
	const dateObject = new Date(flipStringDate(date));
	receiving.value.orderDate = dateObject.toISOString();
	receivingChanged();
}

function setDeliveryDate(date: string) {
	const dateObject = new Date(flipStringDate(date));
	receiving.value.deliveryDate = dateObject.toISOString();
	receivingChanged();
}

function receivingChanged() {
	emits('receivingChanged', receiving);
}

function flipStringDate(date: string): string {
	const splitDate = date.split('-');
	const flipDate = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
	return flipDate;
}
</script>

<template>
	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('receivingDetails.traceId') }}</FormLabel>
		<FormInput v-model="receiving.traceId" type="text" class="w-full" :disabled="props.disabled" />
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('receivingDetails.orderedFor') }}</FormLabel>
		<FormInput v-model="receiving.orderedFor" type="text" class="w-full" :disabled="props.disabled" />
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('receivingDetails.status') }}</FormLabel>
		<FormInput v-model="receiving.status" type="text" class="w-full" :disabled="props.disabled" />
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('receivingDetails.orderDate') }}</FormLabel>
		<div class="relative">
			<div
				class="absolute flex items-center justify-center w-10 h-full border rounded-l bg-slate-100 text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400"
			>
				<Lucide icon="Calendar" class="w-4 h-4" />
			</div>
			<Litepicker
				v-model="pickerOrderDateString"
				@update:modelValue="setOrderDate($event)"
				:options="{
					startDate: pickerOrderDate,
					autoApply: false,
					showWeekNumbers: true,
					format: 'DD-MM-YYYY',
					dropdowns: {
						minYear: 1990,
						maxYear: null,
						months: true,
						years: true
					}
				}"
				class="pl-12"
			/>
		</div>
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('receivingDetails.deliveryDate') }}</FormLabel>
		<div class="relative">
			<div
				class="absolute flex items-center justify-center w-10 h-full border rounded-l bg-slate-100 text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400"
			>
				<Lucide icon="Calendar" class="w-4 h-4" />
			</div>
			<Litepicker
				v-model="pickerDeliveryDateString"
				@update:modelValue="setDeliveryDate($event)"
				:options="{
					startDate: pickerDeliveryDate,
					autoApply: false,
					showWeekNumbers: true,
					format: 'DD-MM-YYYY',
					dropdowns: {
						minYear: 1990,
						maxYear: null,
						months: true,
						years: true
					}
				}"
				class="pl-12"
			/>
		</div>
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('receivingDetails.group') }}</FormLabel>
		<FormInput v-model="receiving.group" type="text" class="w-full" :disabled="props.disabled" />
	</div>
</template>
