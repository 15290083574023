<script setup lang="ts">
import Sort from '../Sort';
import { ref } from 'vue';
import { isEven } from '../../../ts-modules/utils-module/utils/number-utils';
import { IsOrder } from '../../interfaces/orders/is-order';
import { orderDetailsRoute } from '../../constants/routes';
import Lucide from '../../base-components/Lucide/Lucide.vue';

// Properties
const props = defineProps(['bulkHistory']);

// References
const bulkHistory = ref(props.bulkHistory);
const unsortedBulkHistory = ref([...bulkHistory.value]);
const listKey = 'bulkHistoryList';

// Functions
function getOrderNumbers(orders: IsOrder[]): string[] {
	const orderNumbers = orders.map(order => order.orderNumber);
	return orderNumbers;
}

function printDocuments(): void {
	alert('Not yet implemented');
}

function printLabels(): void {
	alert('Not yet implemented');
}
</script>

<template>
	<div class="box h-full break-words">
		<!-- BEGIN: Table head -->
		<div class="grid grid-cols-2 xl:grid-cols-12 p-3 pl-5 font-medium bg-slate-300 dark:bg-slate-900 rounded-t z-50 top-0">
			<div class="xl:hidden">
				{{ $t('bulkHistory.listTitle') }}
			</div>
			<div class="hidden xl:block col-span-2">
				<Sort
					:list-key="listKey"
					:column-title="'uuid'"
					:unsorted-array="unsortedBulkHistory"
					@sorted="bulkHistory = $event"
					class="select-none cursor-pointer"
				></Sort>
			</div>
			<div class="hidden xl:block col-span-2">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'size'"
					:unsorted-array="unsortedBulkHistory"
					@sorted="bulkHistory = $event"
				></Sort>
			</div>
			<div class="hidden xl:block col-span-2">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'status'"
					:unsorted-array="unsortedBulkHistory"
					@sorted="bulkHistory = $event"
				></Sort>
			</div>
			<div class="hidden xl:block col-span-4">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'orderModels'"
					:unsorted-array="unsortedBulkHistory"
					@sorted="bulkHistory = $event"
				></Sort>
			</div>
		</div>
		<!-- END: Table head -->

		<!-- BEGIN: Table content -->
		<div
			v-for="(bulk, index) in bulkHistory"
			:key="bulk.uuid"
			class="grid grid-cols-2 xl:grid-cols-12 p-3 pl-5 break-words"
			:class="[
				{ 'bg-white dark:bg-slate-700': !isEven(index) },
				{ 'bg-secondary dark:bg-slate-800': isEven(index) },
				{ 'rounded-b': bulkHistory.length - 1 === index }
			]"
		>
			<!-- BEGIN: Column 1 -->
			<div class="pb-2 pt-1 xl:pb-1 col-span-2">
				{{ bulk.uuid }}
			</div>
			<!-- END: Column 1 -->

			<!-- BEGIN: Column 2 -->
			<div class="pb-2 pt-1 xl:pb-1 col-span-2">
				{{ bulk.size }}
			</div>
			<!-- END: Column 2 -->

			<!-- BEGIN: Column 3 -->
			<div class="pb-2 pt-1 xl:pb-1 col-span-2">
				{{ bulk.status }}
			</div>
			<!-- END: Column 3 -->

			<!-- BEGIN: Column 4 -->
			<div class="pb-2 pt-1 xl:pb-1 col-span-4">
				<span v-for="(orderNumber, index) in getOrderNumbers(bulk.orderModels)" :key="orderNumber">
					<span v-if="index > 0">, </span><router-link class="link" :to="orderDetailsRoute + orderNumber">{{ orderNumber }}</router-link>
				</span>
			</div>
			<!-- End: Column 4 -->

			<!-- BEGIN: Column 5 -->
			<div class="pb-2 pt-1 xl:pb-1 col-span-2">
				<div class="inline-block w-full h-full">
					<div class="flex justify-end">
						<span class="pr-3 cursor-pointer" @click="printDocuments()"><Lucide icon="FileText"></Lucide></span>
						<span class="cursor-pointer"><Lucide @click="printLabels()" icon="Tag"></Lucide></span>
					</div>
				</div>
			</div>
			<!-- End: Column 5 -->
		</div>
		<!-- END: Table content -->
	</div>
</template>

<style scoped></style>
