<script setup lang="ts">
import { IsReceiving } from '../../interfaces/receivings/is-receiving';
import { dateToString } from '../../../ts-modules/utils-module/utils/date-utils';
import OrderRules from '../OrderRules/OrderRules.vue';
import Button from '../../base-components/Button';
import Lucide from '../../base-components/Lucide';
import router from '../../router';

// Properties
let props = defineProps<{
	receiving: IsReceiving;
}>();

function formatDateString(dateString: string): string {
	return dateToString(new Date(dateString));
}

function routeToReceivingDetails(traceId: string): void {
	router.push({
		name: 'receiving',
		query: {
			traceId
		}
	});
}
</script>

<template>
	<div class="paper dark:bg-slate-700 break-words">
		<!-- BEGIN: Grid -->
		<div class="grid grid-cols-12">
			<div class="col-span-6 pl-5 pt-6 pb-3">
				<h2 class="text-lg font-medium pb-0">
					{{ $t('receivingDetails.receivingTitle') }}:
					{{ props.receiving.traceId }}
				</h2>
			</div>

			<div class="col-span-6 text-right pt-5 pr-5 pb-3">
				<Button @click="routeToReceivingDetails(props.receiving.traceId)" variant="secondary">
					<Lucide icon="Info" class="w-5 h-5" />
				</Button>
			</div>

			<!-- BEGIN: Row -->
			<div class="col-span-12 border-t-2 border-slate-500 rounded ml-3 mr-3">
				<!-- Border only -->
			</div>
			<!-- END: Row -->
			<!-- BEGIN: Row -->
			<div class="col-span-6 p-5">
				<div class="font-bold">
					{{ $t('receivingDetails.orderDetails') }}
				</div>
				<div>
					{{ $t('receivingDetails.traceId') }}:
					{{ receiving.traceId }}
				</div>
				<div>
					{{ $t('receivingDetails.orderedFor') }}:
					{{ receiving.orderedFor }}
				</div>
				<div>
					{{ $t('receivingDetails.group') }}:
					{{ receiving.group }}
				</div>
			</div>

			<div class="col-span-6 p-5">
				<div class="h-5">
					<!--Only height-->
				</div>
				<div>
					{{ $t('receivingDetails.orderDate') }}:
					{{ formatDateString(receiving.orderDate) }}
				</div>
				<div>
					{{ $t('receivingDetails.deliveryDate') }}:
					{{ formatDateString(receiving.deliveryDate) }}
				</div>
				<div>
					{{ $t('receivingDetails.status') }}:
					{{ receiving.status }}
				</div>
			</div>
			<!-- END: Row -->

			<!-- BEGIN: Row -->
			<div class="col-span-12 border-t-2 border-slate-200 rounded ml-3 mr-3">
				<!-- Border only -->
			</div>
			<!-- END: Row -->

			<!-- BEGIN: Row -->
			<div class="col-span-6 p-5">
				<div class="font-bold">{{ $t('receivingDetails.description') }}</div>
				<div>
					{{ receiving.description }}
				</div>
			</div>
			<!-- END: Row -->

			<!-- BEGIN: Row -->
			<div class="col-span-12 border-t-2 border-slate-200 rounded ml-3 mr-3">
				<!-- Border only -->
			</div>
			<!-- END: Row -->

			<!-- BEGIN: Row -->
			<div class="col-span-12 p-5">
				<div class="font-bold">{{ $t('receivingDetails.orderRules') }}</div>

				<div class="pb-2">
					<OrderRules :order-lines="props.receiving.requestLines" :hide-by-name="['attributes']" :no-column-grow-by-name="['pronkId', 'quantity']" />
				</div>
			</div>
			<!-- END: Row -->
		</div>

		<!-- END: Grid -->
	</div>
</template>

<style scoped>
.paper {
	box-shadow: 0px 3px 20px #0000000b;
	background-color: white;
	border-color: transparent;
	border-radius: 0.375rem;
	position: relative;
	min-height: 10vh;
}
</style>
