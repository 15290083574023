import { createRouter, createWebHistory } from 'vue-router';
import SideMenu from '../layouts/SideMenu/SideMenu.vue';
import Bulks from '../pages/bulks/Bulks.vue';
import Orders from '../pages/orders/Orders.vue';
import Order from '../pages/orders/Order.vue';
import Inventory from '../pages/inventory/Inventory.vue';
import Products from '../pages/products/Products.vue';
import Product from '../pages/products/product.vue';
import AddProduct from '../pages/products/AddProduct.vue';
import Receivings from '../pages/receivings/Receivings.vue';
import Receiving from '../pages/receivings/Receiving.vue';

const routes = [
	{
		path: '/',
		component: SideMenu,
		children: [
			{
				path: '/',
				name: 'bulk',
				component: Bulks
			},
			{
				path: 'orders',
				name: 'orders',
				component: Orders
			},
			{
				path: 'orders/order',
				name: 'order',
				component: Order
			},
			{
				path: 'inventory',
				name: 'inventory',
				component: Inventory
			},
			{
				path: 'products',
				name: 'products',
				component: Products
			},
			{
				path: 'products/product',
				name: 'product',
				component: Product
			},
			{
				path: 'products/addProduct',
				name: 'addProduct',
				component: AddProduct
			},
			{
				path: 'receivings',
				name: 'receivings',
				component: Receivings
			},
			{
				path: 'receivings/receiving',
				name: 'receiving',
				component: Receiving
			}
		]
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return savedPosition || { left: 0, top: 0 };
	}
});

export default router;
