<script setup lang="ts">
import { FormInput, FormLabel } from '../../base-components/Form';
import { IsAddress } from '../../interfaces/orders/is-address';
import { ref } from 'vue';

const props = defineProps<{ modelValue: IsAddress; disabled: boolean }>();
const address = ref<IsAddress>(props.modelValue);
</script>

<template>
	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('orderDetails.firstName') }}</FormLabel>
		<FormInput v-model="address.firstName" :disabled="disabled" type="text" class="w-full" />
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('orderDetails.lastName') }}</FormLabel>
		<FormInput v-model="address.lastName" :disabled="disabled" type="text" class="w-full" />
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('orderDetails.address') }}</FormLabel>
		<FormInput v-model="address.street" :disabled="disabled" type="text" class="w-full" />
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('orderDetails.number') }}</FormLabel>
		<FormInput v-model="address.houseNumber" :disabled="disabled" type="text" class="w-full" />
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('orderDetails.postalCode') }}</FormLabel>
		<FormInput v-model="address.postalCode" :disabled="disabled" type="text" class="w-full" />
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('orderDetails.city') }}</FormLabel>
		<FormInput v-model="address.city" :disabled="disabled" type="text" class="w-full" />
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('orderDetails.country') }}</FormLabel>
		<FormInput v-model="address.countryCode" :disabled="disabled" type="text" class="w-full" />
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('orderDetails.phone') }}</FormLabel>
		<FormInput v-model="address.phone" :disabled="disabled" type="text" class="w-full" />
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('orderDetails.email') }}</FormLabel>
		<FormInput v-model="address.email" :disabled="disabled" type="text" class="w-full" />
	</div>
</template>
