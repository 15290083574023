<script setup lang="ts">
import { Slideover } from '../../base-components/Headless';
import Lucide from '../../base-components/Lucide';
import Button from '../../base-components/Button';
import { WebStorage } from '../../../ts-modules/storage-module';
import { ref } from 'vue';
import LoadingIcon from '../../base-components/LoadingIcon';
import { FormSelect } from '../../base-components/Form';
import { ApiType, useApiStore } from '../../stores/api-store';

// Stores
const apiStore = useApiStore();
let api = apiStore.get;
const apiType = ApiType;

// References
const webStorage = WebStorage;
const deletingCache = ref(false);

// Properties
const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue']);

// Functions
function deleteAllCache(): void {
	deletingCache.value = true;
	webStorage.SessionStorage.clearStorage();
	webStorage.LocalStorage.clearStorage();
	location.reload();
}

function changeApi(value: any) {
	api = value;
	apiStore.setApi(api);
	location.reload();
}
</script>

<template>
	<!-- BEGIN: Modal Toggle -->
	<div class="text-center"></div>
	<!-- END: Modal Toggle -->
	<!-- BEGIN: Modal Content -->
	<Slideover size="sm" backdrop="static" :open="modelValue" @close="$emit('update:modelValue', false)">
		<Slideover.Panel>
			<div @click="$emit('update:modelValue', false)" class="absolute top-0 left-0 right-auto mt-4 -ml-12 cursor-pointer">
				<Lucide icon="X" class="w-8 h-8 text-slate-400" />
			</div>
			<Slideover.Title class="p-5">
				<h2 class="mr-auto text-base font-medium">Developer Cheats</h2>
			</Slideover.Title>
			<Slideover.Description>
				<span>Super secret developer cheats menu.</span>

				<div class="mt-5">
					<FormLabel class="font-medium">API</FormLabel>
					<FormSelect class="mt-2" :modelValue="api" @update:modelValue="changeApi($event)">
						<option :value="apiType.production">Production</option>
						<option :value="apiType.develop">Develop</option>
						<option :value="apiType.mockServer">Mock Server</option>
					</FormSelect>
				</div>

				<div class="mt-5">
					<FormLabel class="font-medium">Delete all cache</FormLabel>
					<Button variant="secondary" type="button" class="mt-2 w-full" @click="deleteAllCache">
						Delete cache
						<LoadingIcon v-if="deletingCache" icon="oval" color="white" class="w-4 h-4 ml-2" />
					</Button>
				</div>
			</Slideover.Description>
			<Slideover.Footer>
				<Button variant="secondary" type="button" @click="$emit('update:modelValue', false)" class="w-full mr-1"> Close </Button>
			</Slideover.Footer>
		</Slideover.Panel>
	</Slideover>
	<!-- END: Modal Content -->
</template>
