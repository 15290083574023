import { defineStore } from 'pinia';
// @ts-ignore
import { Icon } from '../base-components/Lucide/Lucide.vue';

export interface Menu {
	icon: Icon;
	title: string;
	pageName?: string[];
	subMenu?: Menu[];
	ignore?: boolean;
}

export interface SideMenuState {
	boxedContent: boolean;
	menu: Array<Menu | 'divider'>;
}

export const useSideMenuStore = defineStore('sideMenu', {
	state: (): SideMenuState => ({
		boxedContent: true,
		menu: [
			{
				icon: 'Package',
				pageName: ['bulk'],
				title: 'bulk'
			},
			{
				icon: 'ShoppingBag',
				pageName: ['orders', 'order'],
				title: 'orders'
			},
			{
				icon: 'PackageSearch',
				pageName: ['products', 'addProduct'],
				title: 'products'
			},
			{
				icon: 'Truck',
				pageName: ['receivings', 'receiving'],
				title: 'receivings'
			}
		]
	})
});
