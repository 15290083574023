<script setup lang="ts">
import { computed } from 'vue';
import Lucide from '../../base-components/Lucide';
import Breadcrumb from '../../base-components/Breadcrumb';
import { Menu } from '../../base-components/Headless';
import { capitalizeFirstLetter } from '../../../ts-modules/utils-module/utils/string-utils';
import { useRoute } from 'vue-router';
import DarkModeSwitcherMenuItem from '../DarkModeSwitcherMenuItem/DarkModeSwitcherMenuItem.vue';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '../../stores/auth-store';

const authStore = useAuthStore();
const accountDetails = computed(() => authStore.getAccountDetails());

const route = useRoute();

const routeName = computed((): string => {
	const name = route.name as string;
	return name;
});

function logout(): void {
	authStore.logoutRedirect();
}
</script>

<template>
	<!-- BEGIN: Top Bar -->
	<div class="h-[67px] z-[51] flex items-center relative border-b border-slate-200">
		<!-- BEGIN: Breadcrumb -->
		<div class="mr-auto -intro-x">
			<Breadcrumb class="hidden sm:flex">
				<Breadcrumb.Link to="/" :active="true">{{ $t(`routes.${routeName}`) }}</Breadcrumb.Link>
			</Breadcrumb>
		</div>

		<!-- END: Breadcrumb -->
		<!-- BEGIN: Account Menu -->
		<Menu class="flex">
			<div class="mr-[25px] mt-2">
				<DarkModeSwitcherMenuItem />
			</div>

			<Menu.Button class="block w-8 h-8 overflow-hidden rounded-full shadow-lg image-fit zoom-in intro-x">
				<img alt="Midone Tailwind HTML Admin Template" :src="accountDetails.avatar" />
			</Menu.Button>
			<Menu.Items class="w-56 mt-px text-white bg-primary">
				<Menu.Header class="font-normal">
					<div class="font-medium">{{ accountDetails.name }}</div>
					<div class="text-xs text-white/70 mt-0.5 dark:text-slate-500">
						{{ accountDetails.username }}
					</div>
				</Menu.Header>
				<Menu.Divider class="bg-white/[0.08]" />
				<Menu.Item class="hover:bg-white/5" @click="logout()">
					<Lucide icon="ToggleRight" class="w-4 h-4 mr-2" />
					Logout
				</Menu.Item>
			</Menu.Items>
		</Menu>
	</div>
	<!-- END: Top Bar -->
</template>
