<script setup lang="ts">
import Lucide from '../../base-components/Lucide';
import { FormSelect } from '../../base-components/Form';
import Litepicker from '../../base-components/Litepicker';
import { ref } from 'vue';
import { IsFilterOption } from './interfaces/is-filter-option';
import { IsFilter } from './interfaces/is-filter';
import { useFilterStore } from './stores/filter-store';
import Button from '../../base-components/Button';
import { copyWithoutReference } from '../../../ts-modules/utils-module/utils/copy-utils';

// Stores
const filterStore = useFilterStore();

// Input & output
const props = defineProps<{ filter: IsFilter }>();
const emits = defineEmits(['filterChanged', 'filterConfirmed']);

// References
const filter = ref(props.filter);

// Init
// Get filter form store
const filterFromStore = copyWithoutReference(filterStore.getFilter(filter.value.filterKey));
if (filterFromStore) {
	filter.value = filterFromStore;
} else {
	if (filter.value.enableButtons) {
		filterStore.setFilter(copyWithoutReference(filter.value));
	}
}

const dynamicCssClasses = `lg:grid-cols-${filter.value.maxRowColumns || '3'} ${filter.value.cssClasses}`;
const screenWith = window.innerWidth;
const mdMinScreenWidth = 768;
setFilterQuickView();

// Functions
function filterChanged(option: IsFilterOption): void {
	// Save change if buttons are disabled
	if (!filter.value.enableButtons) {
		filterStore.setFilter(copyWithoutReference(filter.value));
	}
	emits('filterChanged', option);
}

function getValueFromInput(event: Event): string {
	return (event.target as HTMLInputElement).value;
}

function inputChanged(event: Event, option: IsFilterOption): void {
	option.value = getValueFromInput(event);
	filterChanged(option);
}

function setFilterQuickView(): void {
	filter.value.amountOfNeverHidingFilters = screenWith < mdMinScreenWidth ? 1 : filter.value.amountOfNeverHidingFilters;
}

function updateDateFilter(value: string, filter: IsFilterOption) {
	// Split value
	const splitDate = value.split(' - ');
	// Create dates from split result
	const newStartDate = new Date(splitDate[0]);
	const newEndDate = new Date(splitDate[1]);
	// Set new start date and end date
	filter.startDate = newStartDate;
	filter.endDate = newEndDate;

	filterChanged(filter);
}

function showCompleteFilter(show: boolean): void {
	// Show full filter
	filter.value.showCompleteFilter = show;
	// Check for enable buttons
	if (filter.value.enableButtons) {
		// Get filter unedited filter from store
		const uneditedFilter = copyWithoutReference(filterStore.getFilter(filter.value.filterKey));
		// Edit full filter button status
		if (uneditedFilter) {
			uneditedFilter.showCompleteFilter = show;
			// Save the change
			filterStore.setFilter(copyWithoutReference(uneditedFilter));
		}
	} else {
		filter.value.showCompleteFilter = show;
		filterStore.setFilter(copyWithoutReference(filter.value));
	}
}

function resetFilters(filter: IsFilter): void {
	// Remove all values
	filter.filterOptions.forEach(option => {
		switch (option.optionType) {
			case 'select':
				if (option.options) {
					option.selected = option.options.length > 0 ? option.options[0] : '';
				} else {
					option.selected = '';
				}
				break;
			case 'date':
				option.startDate = new Date(Date.now());
				option.endDate = new Date(Date.now());
				break;
			default:
				option.value = undefined;
				break;
		}
	});
	// Save changes
	filterStore.setFilter(copyWithoutReference(filter));
}

function confirmFilters(filter: IsFilter): void {
	// Save changes
	filterStore.setFilter(copyWithoutReference(filter));

	emits('filterConfirmed', filter);
}
</script>

<template>
	<div class="box">
		<!-- BEGIN: Filter expander -->
		<div
			v-if="filter.filterOptions.length > filter.amountOfNeverHidingFilters"
			class="select-none cursor-pointer absolute bottom-0 right-0 text-gray-500"
		>
			<Lucide v-if="!filter.showCompleteFilter" @click="showCompleteFilter(!filter.showCompleteFilter)" icon="ChevronDown" />

			<Lucide v-else @click="showCompleteFilter(!filter.showCompleteFilter)" icon="ChevronUp" />
		</div>
		<!-- END: Filter expander -->

		<div class="p-5 pt-2 grid grid-cols-1 md:grid-cols-2 rounded" :class="dynamicCssClasses">
			<!-- BEGIN: Filters -->
			<div v-for="(option, index) in filter.filterOptions">
				<div v-if="filter.showCompleteFilter || (!filter.showCompleteFilter && index < filter.amountOfNeverHidingFilters)">
					<!-- BEGIN: Selection filters -->
					<div v-if="option.optionType === 'select'" class="mt-2 md:mr-2">
						<label>{{ $t('' + option.label) }}</label>
						<FormSelect v-model:modelValue="option.selected" @update:model-value="filterChanged(option)" class="mt-2">
							<option v-for="item in option.options" :key="item.value">{{ item }}</option>
						</FormSelect>
					</div>
					<!-- END: Selection filters -->

					<!-- BEGIN: Input filters -->
					<div v-if="option.optionType === 'input'" class="mt-2 md:mr-2">
						<label>{{ $t('' + option.label) }}</label>
						<input
							class="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 mt-2"
							:type="option.dateType"
							:value="option.value"
							@input="inputChanged($event, option)"
						/>
					</div>
					<!-- END: Input filters -->

					<!-- BEGIN: Date filters -->
					<div v-if="option.optionType === 'date'" class="mt-2 md:mr-2">
						<label>{{ $t('' + option.label) }}</label>
						<Litepicker
							class="mt-2 w-full"
							v-model="option.value"
							@update:modelValue="updateDateFilter($event, option)"
							:options="{
								startDate: option.startDate,
								endDate: option.endDate,
								autoApply: false,
								singleMode: false,
								numberOfColumns: 2,
								numberOfMonths: 2,
								showWeekNumbers: true,
								dropdowns: {
									minYear: 1990,
									maxYear: null,
									months: true,
									years: true
								}
							}"
						/>
					</div>
					<!-- END: Date filters -->
				</div>
			</div>
			<!-- END: Filters -->
		</div>

		<div v-if="filter.enableButtons" class="grid grid-cols-1 rounded">
			<div class="pl-1">
				<Button @click="resetFilters(filter)" variant="outline-primary" size="sm" class="w-24 mb-2 ml-4">
					{{ $t('filter.reset') }}
				</Button>
				<Button @click="confirmFilters(filter)" variant="primary" size="sm" class="w-24 mb-2 ml-4">
					{{ $t('filter.confirm') }}
				</Button>
			</div>
		</div>
	</div>
</template>
