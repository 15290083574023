{
  "addProduct": {
    "baseAttributesTitel": "Basiskenmerken",
    "description": "Beschrijving",
    "extraAttributesTitel": "Extra Kenmerken",
    "height": "Hoogte",
    "length": "Lengte",
    "physicality": "Fysiciteit",
    "price": "Prijs",
    "priceUnit": "Valuta",
    "pronkName": "Productnaam",
    "shopChannel": "Shops & Channels",
    "stockType": "Type voorraad",
    "title": "Product toevoegen",
    "unit": "Eenheid",
    "unitType": "Type eenheid",
    "weight": "Gewicht",
    "weightUnit": "Gewichtseenheid",
    "width": "Breedte"
  },
  "bulk": "Bulkbestellingen",
  "bulkHistory": {
    "filterTitle": "Filter",
    "listTitle": "Uitleveringen",
    "title": "Uitlevergeschiedenis"
  },
  "bulkOrderQueueCard": {
    "COMPLETED": "uitleveringen verwerkt",
    "COMPLETED_WITH_ERRORS": "uitleveringen mislukt",
    "FAILED": "uitleveringen mislukt",
    "OPEN": "uitleveringen verwerken",
    "PENDING": "uitleveringen wachtende",
    "PRINTED": "uitleveringen geprint"
  },
  "bulkProposalList": {
    "mobileTitle": "Bulkbestellingen",
    "notifications": {
      "deliverToHigh": {
        "description": "Je hebt meer dan nodig is.",
        "title": "Aantal te hoog!"
      },
      "deliverToLow": {
        "description": "Je kan niet minder uitleveren dan 1.",
        "title": "Aantal te laag!"
      },
      "notEnoughStock": {
        "description": "Er is niet genoeg voorraad.",
        "title": "Aantal te hoog!"
      }
    }
  },
  "bulksPage": {
    "bulkListTitle": "Uitleveringenlijst",
    "bulkQueue": "Afwerklijst",
    "filterTitle": "Filter",
    "pageTitle": "Bulkbestellingen",
    "queue": "Wachtrij",
    "selectionToQueueButton": "Selectie naar wachtrij"
  },
  "filter": {
    "carrier": "Vervoerder",
    "chanel": "Kanaal",
    "confirm": "Bevestigen",
    "countryCodes": "Landcodes",
    "date": "Datum",
    "pronkIds": "Pronk ids",
    "reset": "Reset",
    "search": "Zoeken",
    "shopKeys": "Shop keys",
    "shops": "Winkels",
    "status": "Status",
    "until": "tot"
  },
  "footprintDetails": {
    "close": "Sluiten",
    "orderNumbers": "Ordernummers",
    "title": "Footprint"
  },
  "inventory": "Voorraad",
  "inventoryPage": {
    "pageTitle": "Voorraadbeheer"
  },
  "orderDetails": {
    "addNote": "Voeg een notitie toe",
    "address": "Adres",
    "addresses": "Adressen",
    "backToOrders": "Terug naar orders",
    "BEING_EDITED": "Aanpassen",
    "CANCELED": "Geannuleerd",
    "city": "Plaats",
    "company": "Bedrijf",
    "country": "Land",
    "DELIVERABLE": "Leverbaar",
    "deliveryDate": "Leverdatum",
    "documents": "Documenten",
    "email": "E-mail",
    "firstName": "Voornaam",
    "INVALID": "Ongeldig",
    "lastName": "Achternaam",
    "noNotes": "Geen notities...",
    "noteCreator": "Geplaatst door",
    "notes": "Notities",
    "number": "Nummber",
    "orderDate": "Besteldatum",
    "orderDetails": "Ordergegevens",
    "orderNotFound": "De gevraagde order is niet gevonden.",
    "orderNumber": "Ordernummer",
    "orderRules": "Orderregels",
    "orderTitle": "Order",
    "phone": "Telefoon",
    "postalCode": "Postcode",
    "RECEIVER": "Ontvanger",
    "SENDER": "Verzender",
    "shippingMethod": "Verzendmethode",
    "sorry": "Sorry...",
    "status": "Status",
    "store": "Winkel",
    "title": "Details"
  },
  "orderLines": {
    "mobileTitle": "Orderregels"
  },
  "orderList": {
    "mobileTitle": "Orders"
  },
  "orderPage": {
    "cancel": "Annuleren",
    "pageTitle": "Order wijzigen",
    "save": "Opslaan"
  },
  "orders": "Orders",
  "ordersPage": {
    "filterTitle": "Filter",
    "orderListTitle": "Orderlijst",
    "pageTitle": "Orders"
  },
  "productAttributes": {
    "DESCRIPTION": "Beschrijving",
    "DIMENSIONS": "Afmetingen",
    "PRICE": "Prijs",
    "title": "Product attributen",
    "VOLUME": "Volume",
    "WEIGHT": "Gewicht"
  },
  "productDetails": {
    "attributes": "Eigenschappen",
    "channel": "Channel",
    "description": "Beschrijven",
    "dimensions": "Afmetingen",
    "physicality": "Fysiciteit",
    "price": "Prijs",
    "productName": "Productnaam",
    "productTitle": "Product",
    "pronkId": "Pronk Id",
    "shop": "Shops",
    "stockType": "Type vooraad",
    "title": "Productgegevens",
    "unitType": "Type eenheid",
    "volume": "Volume",
    "weight": "Gewicht"
  },
  "productList": {
    "mobileTitle": "Producten"
  },
  "productNotes": {
    "lastModifiedDate": "Datum",
    "mobileTitle": "Notitites",
    "notes": "Notitites",
    "pronkId": "PronkId"
  },
  "productPage": {
    "cancel": "Annuleren",
    "pageTitle": "Product wijzigen",
    "save": "Opslaan"
  },
  "receivingPage": {
    "cancel": "Annuleren",
    "pageTitle": "T.O.G. bestelling wijzigen",
    "save": "Opslaan"
  },
  "products": "Producten",
  "productsFilter": {
    "channel": "Channels",
    "physicality": "Fysiciteit",
    "pronkId": "Pronk Id",
    "search": "Zoeken",
    "shop": "Shops",
    "stockType": "Type voorraad",
    "unitType": "Type eenheid"
  },
  "productsPage": {
    "add": "Toevoegen",
    "filterTitle": "Filter",
    "pageTitle": "Producten",
    "productListTitle": "Productlijst"
  },
  "receivingDetails": {
    "title": "Details T.O.G.",
    "deliveryDate": "Bezorgdatum",
    "description": "Beschrijving",
    "group": "Groep",
    "orderDate": "Besteldatum",
    "orderDetails": "Bestelgegevens",
    "orderedFor": "Besteld voor",
    "orderRules": "Bestelregels",
    "receivingTitle": "Bestelling",
    "status": "Status",
    "traceId": "Trace ID"
  },
  "receivingList": {
    "mobileTitle": "Bestelde goederen"
  },
  "receivings": "T.O.G.",
  "receivingsPage": {
    "filterTitle": "Filter",
    "pageTitle": "Te ontvangen goederen",
    "receivingListTitle": "Bestelde goederen"
  },
  "refreshButton": {
    "title": "Refresh"
  },
  "routes": {
    "bulk": "Bulkbestellingen",
    "order": "Bestelling",
    "orders": "Bestellingen",
    "product": "Product",
    "products": "Producten",
    "receivings": "T.O.G.",
    "receiving": "T.O.G. bestelling"
  },
  "sorts": {
    "bulkHistoryList": {
      "orderModels": "Ordernummers",
      "size": "Aantal",
      "status": "Status",
      "uuid": "UUID"
    },
    "bulkProposalList": {
      "deliver": "Leveringen",
      "footprint": "Footprint",
      "packaging": "Verpakking",
      "products": {
        "pronkId": "Inhoud"
      },
      "quantity": "Aantal",
      "stock": "Voorraad"
    },
    "orderList": {
      "addresses": {
        "firstName": "Naam",
        "postalCode": "Adres"
      },
      "orderDate": "Orderdatum",
      "orderNumber": "Ordernummer",
      "products": {
        "pronkId": "Inhoud"
      }
    },
    "orderRules": {
      "deliveredQuantity": "Leveringen",
      "eanCode": "Ean-code",
      "mobileTitle": "Orderregels",
      "name": "Productnaam",
      "pronkId": "PronkId",
      "quantity": "Leveringen"
    },
    "productList": {
      "name": "Productnaam",
      "physicality": "Fysiciteit",
      "pronkId": "PronkId",
      "stockType": "Type voorraad",
      "unitType": "Type eenheid"
    },
    "productNotes": {
      "lastModifiedDate": "Datum",
      "notes": "Notitites"
    },
    "receivingList": {
      "deliveryDate": "Leverdatum",
      "orderedFor": "Besteld voor",
      "status": "Status",
      "traceId": "Trace ID",
      "type": "Type"
    }
  }
}
