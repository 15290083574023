<script setup lang="ts">
// Needed for select box !!!
import FormCheck from '../../base-components/Form/FormCheck/';
import { provide, ref, watch } from 'vue';
import { FormInput } from '../../base-components/Form';
import Sort from '../Sort';
import { copyWithoutReference } from '../../../ts-modules/utils-module/utils/copy-utils';
import { NotificationElement } from '../../base-components/Notification';
import ToastNotification from '../ToastNotification/ToastNotification.vue';
import { IsNotification } from '../../interfaces/notifications/IsNotification';
import { useI18n } from 'vue-i18n';
import FootprintDetailsSlideOver from '../FootprintDetailsSlideOver/FootprintDetailsSlideOver.vue';
import { IsFootprintDetail } from '../../interfaces/proposals/is-footprint-detail';
import { useFootprintDetailsSlideOverStore } from './stores/footprint-details-slide-over-store';
import { useBulkProposalStore } from '../../stores/bulk-proposal-store';
import { IsBulkProposal } from '../../interfaces/proposals/is-bulk-proposal';
import { IsProposalProduct } from '../../interfaces/proposals/is-proposal-product';
import { IsAvailabilityRequest } from '../../interfaces/inventory/is-availability-request';
import { useInventoryStore } from '../../stores/inventory-store';
import { IsProposal } from '../../interfaces/proposals/is-proposal';
import { NumberUtils } from '../../../ts-modules/utils-module';

const { t } = useI18n();

// Stores
const bulkProposalStore = useBulkProposalStore();
const inventoryStore = useInventoryStore();
const footprintDetailsSlideOverStore = useFootprintDetailsSlideOverStore();

// Properties
const props = defineProps(['selectionDisabled']);

// References
let proposal = ref(bulkProposalStore.get);
let unSortedProposals = ref(proposal.value.proposals);
const footprintDetailsVisible = ref(footprintDetailsSlideOverStore.get.footprintDetailsVisible);
const footprintDetails = ref<IsFootprintDetail>(footprintDetailsSlideOverStore.get.footprintDetails);
const listKey = ref('bulkProposalList');

// Notifications
const deliverToHighToast = createNotification(
	'deliverToHigh',
	'XCircle',
	t('bulkProposalList.notifications.deliverToHigh.title'),
	t('bulkProposalList.notifications.deliverToHigh.description')
);

const notEnoughStockToast = createNotification(
	'notEnoughStock',
	'XCircle',
	t('bulkProposalList.notifications.notEnoughStock.title'),
	t('bulkProposalList.notifications.notEnoughStock.description')
);

const deliverToLowToast = createNotification(
	'deliverToLow',
	'XCircle',
	t('bulkProposalList.notifications.deliverToLow.title'),
	t('bulkProposalList.notifications.deliverToLow.description')
);

const hideToastAfter = 3000;

// Init logic
if (proposal.value.total < 1) {
	// Get bulk proposal
	const bulkProposal: IsProposal = await getBulkProposals();
	// Set quantity in state
	bulkProposalStore.setProposals(bulkProposal.proposals);
	bulkProposalStore.setDefaultProposals(bulkProposal.proposals);
	// Cache state
	bulkProposalStore.cacheState();
	// Show bulks proposals
	proposal = ref(bulkProposalStore.get);
	// Save unsorted proposals
	unSortedProposals.value = proposal.value.proposals;
}

// Watchers
// Listen for footprint details visibility changes
watch(footprintDetailsVisible, value => {
	if (!value) {
		// Reset footprint details
		footprintDetailsSlideOverStore.resetFootprintDetails();
		footprintDetailsSlideOverStore.setFootprintDetailsVisible(false);
		// Get new state
		footprintDetails.value = footprintDetailsSlideOverStore.get.footprintDetails;
	}
});

// Functions
async function getBulkProposals(): Promise<IsProposal> {
	return new Promise<IsProposal>(resolve => {
		// Download proposals
		bulkProposalStore.downloadProposals().then(async () => {
			let proposalsFromServer = copyWithoutReference(bulkProposalStore.get);

			// Set proposal quantities
			proposalsFromServer.proposals = await addStock(proposalsFromServer.proposals);
			proposalsFromServer.proposals = addProposalBulkQuantity(proposalsFromServer.proposals);
			proposalsFromServer.proposals = proposalsFromServer.proposals.map((proposal: IsBulkProposal) => setBulkDeliver(proposal));

			resolve(proposalsFromServer);
		});
	});
}

function addStock(bulkProposals: IsBulkProposal[]): Promise<IsBulkProposal[]> {
	return new Promise<IsBulkProposal[]>(resolve => {
		const requests: IsAvailabilityRequest[] = [];
		// Create requests for every proposal
		for (const bulkProposal of bulkProposals) {
			if (bulkProposal.products.length > 0) {
				// Create request
				const request: IsAvailabilityRequest = {
					mode: 'SIMPLE',
					shopKey: bulkProposal.shopKey,
					content: {}
				};

				// Add content
				for (const product of bulkProposal.products) {
					request.content[product.pronkId] = product.quantity;
				}

				// Add to requests list
				requests.push(request);
			}
		}

		// Add available stock to proposals
		inventoryStore
			.checkAvailability(requests)
			.then(availability => {
				let index = 0;
				for (const multiplier of availability.multipliers) {
					const bulkProposal = bulkProposals[index];
					bulkProposal.stock = multiplier;
					index++;
				}

				resolve(bulkProposals);
			})
			.catch(error => {
				console.log(error);
				resolve(bulkProposals);
			});
	});
}

function addProposalBulkQuantity(bulkProposals: IsBulkProposal[]): IsBulkProposal[] {
	// Set the lowest quantity of products as bulk quantity
	for (const bulkProposal of bulkProposals) {
		for (const product of bulkProposal.products) {
			if (product.quantity < bulkProposal.quantity || !bulkProposal.quantity) {
				bulkProposal.quantity = product.quantity;
			}
		}
	}
	return bulkProposals;
}

function setBulkDeliver(bulkProposal: IsBulkProposal): IsBulkProposal {
	// Check if deliver is not set
	if (!bulkProposal.deliver) {
		// Set delivery as far there is stock
		bulkProposal.deliver = bulkProposal.stock >= bulkProposal.quantity ? bulkProposal.quantity : bulkProposal.stock;
	}
	return bulkProposal;
}

function getProposalProductNames(products: IsProposalProduct[]): string[] {
	let names = [];
	for (const product of products) {
		names.push(product.pronkId);
	}
	return names;
}

function selectBulkProposal(bulkProposal: IsBulkProposal) {
	bulkProposal.selected = !bulkProposal.selected;
	bulkProposalStore.setProposals(proposal.value.proposals);
	bulkProposalStore.cacheState();
}

function checkInput(bulkProposal: IsBulkProposal, input: any) {
	// Get delivery input
	let deliverInput = +input.target.value;

	switch (true) {
		case deliverInput > bulkProposal.quantity:
			// Set stock as deliver quantity
			bulkProposal.deliver = bulkProposal.quantity;
			// Show notification
			deliverToHighToast.refNotification.value?.showToast();
			// Hide toast
			setTimeout(() => {
				deliverToHighToast.refNotification.value?.hideToast();
			}, hideToastAfter);
			break;
		case deliverInput > bulkProposal.stock:
			// Set stock as deliver quantity
			bulkProposal.deliver = bulkProposal.stock;
			// Show notification
			notEnoughStockToast.refNotification.value?.showToast();
			// Hide toast
			setTimeout(() => {
				notEnoughStockToast.refNotification.value?.hideToast();
			}, hideToastAfter);
			break;
		case deliverInput < 1:
			// Set stock as deliver quantity
			bulkProposal.deliver = 1;
			// Show notification
			deliverToLowToast.refNotification.value?.showToast();
			// Hide toast
			setTimeout(() => {
				deliverToLowToast.refNotification.value?.hideToast();
			}, hideToastAfter);
	}
}

function createNotification(refKey: string, icon: any, title: string, description: string): IsNotification {
	const notification: IsNotification = {
		refNotification: ref<NotificationElement>(),
		toastNotification: {
			refKey,
			icon,
			title,
			description
		}
	};

	provide(`bind[${refKey}]`, (el: NotificationElement) => {
		notification.refNotification.value = el;
	});

	return notification;
}

function showFootprintDetails(bulkProposal: IsBulkProposal): void {
	footprintDetails.value = {
		footprint: bulkProposal.footprint,
		orderNumbers: bulkProposal.orderNumbers
	};
	// Show slide over
	footprintDetailsVisible.value = true;
	// Save visibility and details
	footprintDetailsSlideOverStore.setFootprintDetailsVisible(footprintDetailsVisible.value);
	footprintDetailsSlideOverStore.setFootprintDetails(footprintDetails.value);
}

function isEven(number: number): boolean {
	return NumberUtils.isEven(number);
}
</script>

<template>
	<ToastNotification v-model="deliverToHighToast.toastNotification" />
	<ToastNotification v-model="deliverToLowToast.toastNotification" />
	<ToastNotification v-model="notEnoughStockToast.toastNotification" />

	<div class="box h-full break-words">
		<!-- BEGIN: Table head -->
		<div class="grid grid-cols-2 xl:grid-cols-12 p-3 pl-5 font-medium bg-slate-300 dark:bg-slate-900 rounded-t sticky z-50 top-0">
			<div class="xl:hidden">
				{{ $t('bulkProposalList.mobileTitle') }}
			</div>
			<div class="hidden xl:block">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'deliver'"
					:unsorted-array="unSortedProposals"
					@sorted="proposal.proposals = $event"
				></Sort>
			</div>
			<div class="hidden xl:block">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'stock'"
					:align="'center'"
					:unsorted-array="unSortedProposals"
					@sorted="proposal.proposals = $event"
				></Sort>
			</div>
			<div class="hidden xl:block col-span-2">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'quantity'"
					:align="'center'"
					:unsorted-array="unSortedProposals"
					@sorted="proposal.proposals = $event"
				></Sort>
			</div>
			<div class="hidden xl:block col-span-2">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'packaging'"
					:unsorted-array="unSortedProposals"
					@sorted="proposal.proposals = $event"
				></Sort>
			</div>
			<div class="hidden xl:block col-span-2">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'footprint'"
					:unsorted-array="unSortedProposals"
					@sorted="proposal.proposals = $event"
				></Sort>
			</div>
			<div class="hidden xl:block col-span-2 pt-2 xl:col-span-4 xl:pt-0 xl:pl-10">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'products.pronkId'"
					:unsorted-array="unSortedProposals"
					@sorted="proposal.proposals = $event"
				></Sort>
			</div>
		</div>
		<!-- END: Table head -->

		<!-- BEGIN: Table content -->
		<div
			v-for="(bulkProposal, index) in proposal.proposals"
			:key="`bulk-proposal-${index}`"
			class="grid grid-cols-2 xl:grid-cols-12 p-3 pl-5 intro-x"
			:class="[
				{ 'bg-white hover:bg-slate-300 dark:bg-slate-700 dark:hover:bg-slate-600': !isEven(index) },
				{ 'bg-secondary hover:bg-slate-300 dark:bg-slate-800 dark:hover:bg-slate-600': isEven(index) },
				{ 'rounded-b': proposal.total - 1 === index }
			]"
		>
			<!-- BEGIN: Extra for mobile only -->
			<div class="pb-2 col-span-1 xl:hidden">
				<div class="flex flex-row pt-1">
					<div class="grow whitespace-nowrap">
						<FormCheck.Input class="scale-150" type="checkbox" :modelValue="bulkProposal.selected" @change="selectBulkProposal(bulkProposal)" />
					</div>
					<div class="xl:pr-3 w-full">
						<FormCheck.Label class="whitespace-nowrap pr-3"> {{ $t(`sorts.${listKey}.deliver`) }}:</FormCheck.Label>
					</div>
				</div>
			</div>
			<!-- END: Extra for mobile only -->

			<!-- BEGIN: Column 1 -->
			<div class="pl-3 pb-2 xl:pb-0 relative z-0">
				<div class="flex flex-row relative xl:-left-3 z-0">
					<div class="hidden xl:block pt-1 pr-3">
						<FormCheck.Input
							class="scale-150"
							type="checkbox"
							:disabled="props.selectionDisabled"
							:modelValue="bulkProposal.selected"
							@change="selectBulkProposal(bulkProposal)"
						/>
					</div>
					<div>
						<FormInput
							formInputSize="sm"
							v-model="bulkProposal.deliver"
							type="number"
							:min="0"
							:max="bulkProposal.stock"
							@input="checkInput(bulkProposal, $event)"
							:disabled="props.selectionDisabled"
						/>
					</div>
				</div>
			</div>
			<!-- END: Column 1 -->

			<!-- BEGIN: Column 2 -->
			<div class="pb-2 pt-1 xl:pb-1">
				<span class="xl:hidden">{{ $t(`sorts.${listKey}.stock`) }}:</span> {{ bulkProposal.stock }}
			</div>
			<!-- END: Column 2 -->

			<!-- BEGIN: Column 3 -->
			<div class="pb-2 pt-1 xl:pb-1 xl:col-span-2 xl:text-center">
				<span class="xl:hidden">{{ $t(`sorts.${listKey}.quantity`) }}:</span> {{ bulkProposal.quantity }}
			</div>
			<!-- END: Column 3 -->

			<!-- BEGIN: Column 4 -->
			<div class="hidden xl:block xl:col-span-2 pt-1 pb-1">
				{{ bulkProposal.packaging }}
			</div>
			<!-- End: Column 4 -->

			<!-- BEGIN: Column 5 - xl only -->
			<div class="hidden xl:block xl:col-span-2 pt-1 pb-1 cursor-pointer">
				<div @click="showFootprintDetails(bulkProposal)">{{ bulkProposal.footprint }}</div>
			</div>
			<!-- END: Column 5 - xl only -->

			<!-- BEGIN: Column 5 - mobile only -->
			<div class="col-span-2 xl:hidden pb-2">
				<span class="xl:hidden">{{ $t(`sorts.${listKey}.packaging`) }}: </span>
				<span class="break-all">{{ bulkProposal.packaging }}</span>
			</div>
			<!-- END: Column 5 - mobile only -->

			<!-- BEGIN: Column 6 - mobile only -->
			<div class="col-span-2 xl:hidden cursor-pointer">
				{{ $t(`sorts.${listKey}.footprint`) }}:
				<div @click="showFootprintDetails(bulkProposal)">{{ bulkProposal.footprint }}</div>
			</div>
			<!-- END: Column 6 - mobile only -->

			<!-- BEGIN: Column 7 - mobile only -->
			<div class="col-span-2 xl:hidden pt-2">
				<hr class="h-0.5 mb-1 bg-slate-300 dark:bg-slate-400" />
			</div>
			<!-- END: Column 7 - mobile only -->

			<!-- BEGIN: Column 6 & 8  -->
			<div class="col-span-2 xl:col-span-4 xl:pt-1 xl:pb-1 xl:pl-10 max-h-40 xl:max-h-20 overflow-y-auto">
				<span v-for="(name, index) of getProposalProductNames(bulkProposal.products)"> <span v-if="index > 0"> - </span> {{ name }} </span>
			</div>
			<!-- END: Column 6 & 8 -->
		</div>
		<!-- END: Table content -->
	</div>

	<FootprintDetailsSlideOver v-model:open="footprintDetailsVisible" v-model:footprint-details="footprintDetails"></FootprintDetailsSlideOver>
</template>

<style scoped>
/* Disable number input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Disable number input arrows for Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
</style>
