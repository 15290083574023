<script setup lang="ts">
// Needed for select box !!!
import FormCheck from '../../base-components/Form/FormCheck/';
import { ref } from 'vue';
import Sort from '../Sort';
import { copyWithoutReference } from '../../../ts-modules/utils-module/utils/copy-utils';
import { useOrdersStore } from '../../stores/orders-store';
import { IsOrder } from '../../interfaces/orders/is-order';
import { dateToString } from '../../../ts-modules/utils-module/utils/date-utils';
import { getReceiverAddress } from '../../utils/order-utils';
import { NumberUtils } from '../../../ts-modules/utils-module';

// Stores
const orderStore = useOrdersStore();

// Properties
const emit = defineEmits<{
	(e: 'clicked', order: IsOrder): void;
	(e: 'rerender'): void;
}>();

// References
const orders = ref(orderStore.getOrders);
const unsortedOrders = ref([...orders.value]);
const listKey = 'orderList';

// Init logic
if (orders.value.length < 1) {
	// Get orders
	await orderStore.resetOrders();
	const ordersFormServer = orderStore.getOrders;
	// Save states
	orderStore.cacheState();
	// Show orders
	orders.value = ordersFormServer;
	// Reset list
	forceListRerender();
}

// Functions
function forceListRerender(): void {
	emit('rerender');
}

function isEven(number: number): boolean {
	return NumberUtils.isEven(number);
}

function orderClicked(order: IsOrder): void {
	emit('clicked', order);
}
</script>

<template>
	<div class="box break-words absolute h-full w-full overflow-x-hidden">
		<!-- BEGIN: Table head -->
		<div class="flex flex-row p-3 pl-5 font-medium bg-slate-300 dark:bg-slate-900 rounded-t sticky z-50 top-0">
			<div class="sm:hidden">
				{{ $t('orderList.mobileTitle') }}
			</div>
			<div class="hidden sm:block flex-none w-32 pr-5">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'orderNumber'"
					:unsorted-array="unsortedOrders"
					@sorted="orders = $event"
				></Sort>
			</div>
			<div class="hidden sm:block flex-auto w-32 pr-5">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'addresses.firstName'"
					:unsorted-array="unsortedOrders"
					@sorted="orders = $event"
				></Sort>
			</div>
			<div class="hidden sm:block flex-auto w-32 pr-5">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'addresses.postalCode'"
					:unsorted-array="unsortedOrders"
					@sorted="orders = $event"
				></Sort>
			</div>
			<div class="hidden sm:block flex-auto w-32 pr-5">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'orderDate'"
					:unsorted-array="unsortedOrders"
					@sorted="orders = $event"
				></Sort>
			</div>
		</div>
		<!-- END: Table head -->

		<!-- BEGIN: Table content -->
		<div
			v-for="(order, index) in orders"
			:key="order.orderNumber"
			class="flex flex-row p-3 pl-5 cursor-pointer intro-x"
			:class="[
				{ 'bg-white hover:bg-slate-300 dark:bg-slate-700 dark:hover:bg-slate-600': !isEven(index) },
				{ 'bg-secondary hover:bg-slate-300 dark:bg-slate-800 dark:hover:bg-slate-600': isEven(index) },
				{ 'rounded-b': orders.length - 1 === index }
			]"
			@click="orderClicked(order)"
		>
			<!-- BEGIN: Column 1 -->
			<div class="sm:pl-3 pb-2 sm:pb-0 relative z-0 flex-none w-32 pr-5">
				<div class="sm:-left-3 z-0">
					<div class="sm:hidden font-bold">{{ $t(`sorts.${listKey}.orderNumber`) }}</div>
					<div class="ellipsis">{{ order.orderNumber }}</div>
				</div>
			</div>
			<!-- END: Column 1 -->

			<!-- BEGIN: Column 2 -->
			<div class="pb-2 sm:pb-1 flex-auto w-32 pr-5">
				<div class="sm:hidden font-bold">{{ $t(`sorts.${listKey}.addresses.firstName`) }}</div>
				<div>{{ getReceiverAddress(order)?.firstName + ' ' + getReceiverAddress(order)?.lastName }}</div>
			</div>
			<!-- END: Column 2 -->

			<!-- BEGIN: Column 3 -->
			<div class="pb-2 pt-1 sm:pb-1 flex-auto w-32 pr-5">
				<div class="sm:hidden font-bold">{{ $t(`sorts.${listKey}.addresses.postalCode`) }}:</div>
				<div>
					{{ getReceiverAddress(order)?.postalCode + ' ' + getReceiverAddress(order)?.city + ', ' + getReceiverAddress(order)?.houseNumber }}
				</div>
			</div>
			<!-- END: Column 3 -->

			<!-- BEGIN: Column 4 -->
			<div class="pt-1 pb-1 flex-auto w-32 pr-5">
				<div class="sm:hidden font-bold">{{ $t(`sorts.${listKey}.orderDate`) }}:</div>
				<div>{{ dateToString(new Date(order.orderDate)) }}</div>
			</div>
			<!-- End: Column 4 -->
		</div>
		<!-- END: Table content -->
	</div>
</template>

<style scoped>
/* Disable number input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Disable number input arrows for Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
</style>
