<script setup lang="ts">
import { ref } from 'vue';
import { IsOrderLine } from '../../interfaces/orders/is-order-line';
import { NumberUtils } from '../../../ts-modules/utils-module';
import Sort from '../Sort';
import { IsReceivingRequestLine } from '../../interfaces/receivings/is-receiving-request-line';

// Properties
interface orderLinesConfig {
	orderLines: IsOrderLine[] | IsReceivingRequestLine[];
	hideByName?: string[];
	noColumnGrowByName?: string[];
}

const props = defineProps<orderLinesConfig>();

// References
const gridRowsRef = ref();
let headerNames = ref(getColumnNames(props.orderLines));
const listKey = ref('orderRules');
const orderLines = ref(props.orderLines);
const unsortedOrderLines = ref([...orderLines.value]);

// Init logic
if (props.hideByName) {
	props.hideByName.forEach(name => removeFromHeaderNames(name));
}

// Functions
function getColumnNames(orderLines: IsOrderLine[] | IsReceivingRequestLine[]) {
	// Check for order lines
	if (orderLines.length) {
		// Return property names of the first item
		return Object.getOwnPropertyNames(props.orderLines[0]);
	} else {
		return [];
	}
}

function isEven(number: number): boolean {
	return NumberUtils.isEven(number);
}

function getOrderLineValueByPropertyName(orderLine: any, propertyName: string): any {
	return orderLine[propertyName];
}

function removeFromHeaderNames(name: string): void {
	headerNames.value = headerNames.value.filter(headerName => headerName !== name);
}

function noColumnGrow(name: string): boolean {
	if (!props.noColumnGrowByName) {
		return false;
	} else {
		return props.noColumnGrowByName.includes(name);
	}
}
</script>

<template>
	<div class="box h-full break-words overflow-x-hidden">
		<!-- BEGIN: Table head -->
		<div class="flex flex-row p-3 pl-5 font-medium bg-slate-300 dark:bg-slate-900 rounded-t sticky z-50 top-0">
			<div class="sm:hidden">
				{{ $t(`sorts.${listKey}.mobileTitle`) }}
			</div>
			<div v-for="header in headerNames" :key="header" class="hidden sm:block flex-auto w-32 pr-5" :class="[{ 'flex-none': noColumnGrow(header) }]">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="header"
					:unsorted-array="unsortedOrderLines"
					@sorted="orderLines = $event"
				></Sort>
			</div>
		</div>
		<!-- END: Table head -->

		<!-- BEGIN: Table content -->
		<div ref="gridRowsRef">
			<div
				v-for="(orderLine, index) in orderLines"
				:key="'orderLine_' + index"
				class="flex flex-row p-3 pl-5 intro-x"
				:class="[
					{ 'bg-white hover:bg-slate-300 dark:bg-slate-700 dark:hover:bg-slate-600': !isEven(index) },
					{ 'bg-secondary hover:bg-slate-300 dark:bg-slate-800 dark:hover:bg-slate-600': isEven(index) },
					{ 'rounded-b': orderLines.length - 1 === index }
				]"
			>
				<div v-for="header in headerNames" :key="header" class="flex-auto w-32 pr-5" :class="[{ 'flex-none': noColumnGrow(header) }]">
					<div class="sm:hidden font-bold">{{ $t(`sorts.${listKey}.${header}`) }}</div>
					<div :class="[{ ellipsis: noColumnGrow(header) }]">
						{{ getOrderLineValueByPropertyName(orderLine, header) }}
					</div>
				</div>
			</div>
		</div>
		<!-- END: Table content -->
	</div>
</template>

<style scoped>
/* Disable number input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Disable number input arrows for Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
</style>
