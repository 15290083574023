<script setup lang="ts" async>
import PageTitle from '../../components/PageTitle';
import { copyWithoutReference } from '../../../ts-modules/utils-module/utils/copy-utils';
import { useProductsStore } from '../../stores/products-store';
import { IsProduct } from '../../interfaces/Products/is-product';
import { ref } from 'vue';
import ProductDetails from '../../components/ProductDetails/ProductDetails.vue';
import { useRoute } from 'vue-router';
import Button from '../../base-components/Button';
import { useSideMenuStore } from '../../stores/side-menu';
import router from '../../router';
import ProductAttribute from '../../components/ProductAttributes/ProductAttributes.vue';

// Tools
const route = useRoute();

// Stores
const productsStore = useProductsStore();
const sideMenuStore = useSideMenuStore();

// Variables
let originalProduct: IsProduct;

// References
const editedProduct = ref<IsProduct | undefined>(productsStore.getSelectedProduct);
const detailsKey = ref(0);

// Init logic
disableBoxedContentStyle(true);
loadProduct(route.query.pronkId as string);

// Functions
function disableBoxedContentStyle(disable: boolean): void {
	sideMenuStore.boxedContent = !disable;
	if (disable) {
		// Re-enable boxed content style when leaving page
		router.beforeEach(() => {
			sideMenuStore.boxedContent = true;
		});
	}
}

async function loadProduct(pronkId: string): Promise<void> {
	// eslint-disable-next-line no-async-promise-executor
	return new Promise<void>(async resolve => {
		let product: IsProduct | undefined;
		const selectedProduct = productsStore.getSelectedProduct;

		// Get product
		if (selectedProduct?.pronkId === pronkId) {
			product = selectedProduct;
		} else {
			// Get all products
			const products = productsStore.getProducts;
			if (products.length > 0) {
				// Get specific order from cache
				product = products.find(product => product.pronkId === pronkId) as IsProduct;
			} else {
				product = undefined;
			}
		}

		// Set product as selected
		productsStore.setSelectedProduct(product);

		// Copy order for editing
		originalProduct = copyWithoutReference(product);
		editedProduct.value = copyWithoutReference(product);

		console.log(product);
		resolve();
	});
}

function reRender(): void {
	reRenderDetails();
}

function reRenderDetails(): void {
	detailsKey.value++;
}

function saveProductChanges(product: IsProduct | undefined): void {
	if (product) {
		originalProduct = copyWithoutReference(product);
		productsStore.setSelectedProduct(copyWithoutReference(product));
		productsStore.cache();
		reRender();
	}
}

function cancelProductChanges(): void {
	if (editedProduct.value) {
		editedProduct.value = copyWithoutReference(originalProduct);
		productsStore.setSelectedProduct(copyWithoutReference(editedProduct.value));
		reRender();
	}
}
</script>

<template>
	<div class="">
		<!-- BEGIN: Title row	-->
		<div class="flex flex-row">
			<div class="flex-1 -intro-x">
				<PageTitle>{{ $t('productPage.pageTitle') }}</PageTitle>
			</div>
			<div class="grow intro-y">
				<div class="mt-8 float-right">
					<Button @click="saveProductChanges(editedProduct)" variant="primary" class="w-24 mb-2 ml-4 float-right">
						{{ $t('productPage.save') }}
					</Button>
					<Button @click="cancelProductChanges()" variant="outline-primary" class="w-24 mb-2 ml-4 float-right">
						{{ $t('productPage.cancel') }}
					</Button>
				</div>
			</div>
		</div>
		<!-- END: Title row	-->

		<!-- BEGIN: Editor boxes	-->
		<div class="grid grid-cols-12 gap-2 mt-3">
			<!-- BEGIN: Product details	-->
			<div class="col-span-12 xl:col-span-8 -intro-x box p-5">
				<h3 class="text-base font-medium">{{ $t('productDetails.title') }}</h3>
				<ProductDetails v-if="editedProduct" :key="detailsKey" v-model="editedProduct"></ProductDetails>
			</div>
			<!-- END: Product details	-->

			<!-- BEGIN: Product attributes	-->
			<div class="col-span-12 xl:col-span-4 intro-x box p-5">
				<h3 class="text-base font-medium">{{ $t('productAttributes.title') }}</h3>
				<ProductAttribute v-if="editedProduct" :key="detailsKey" v-model="editedProduct.attributes"></ProductAttribute>
			</div>
			<!-- END: Product attributes	-->
		</div>
	</div>
</template>
