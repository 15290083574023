<script setup lang="ts">
import { FormInput, FormLabel } from '../../base-components/Form';
import { ref } from 'vue';
import { IsProductAttribute } from '../../interfaces/Products/is-product-attribute';

// Properties
const props = defineProps<{ modelValue: IsProductAttribute; disabled?: boolean }>();

// References
const attributes = ref<IsProductAttribute>(props.modelValue);
</script>

<template>
	<div class="pt-4" v-if="attributes.DIMENSIONS">
		<FormLabel class="font-medium">{{ $t('productAttributes.DIMENSIONS') }}</FormLabel>
		<FormInput v-model="attributes.DIMENSIONS.value" type="text" class="w-full" :disabled="props.disabled" />
	</div>

	<div class="pt-4" v-if="attributes.VOLUME">
		<FormLabel class="font-medium">{{ $t('productAttributes.VOLUME') }}</FormLabel>
		<FormInput v-model="attributes.VOLUME.value" type="text" class="w-full" :disabled="props.disabled" />
	</div>

	<div class="pt-4" v-if="attributes.WEIGHT">
		<FormLabel class="font-medium">{{ $t('productAttributes.WEIGHT') }}</FormLabel>
		<FormInput v-model="attributes.WEIGHT.value" type="text" class="w-full" :disabled="props.disabled" />
	</div>

	<div class="pt-4" v-if="attributes.DESCRIPTION">
		<FormLabel class="font-medium">{{ $t('productAttributes.DESCRIPTION') }}</FormLabel>
		<FormInput v-model="attributes.DESCRIPTION.value" type="text" class="w-full" :disabled="props.disabled" />
	</div>

	<div class="pt-4" v-if="attributes.PRICE">
		<FormLabel class="font-medium">{{ $t('productAttributes.PRICE') }}</FormLabel>
		<FormInput v-model="attributes.PRICE.value" type="text" class="w-full" :disabled="props.disabled" />
	</div>
</template>
