<script setup lang="ts" async>
import PageTitle from '../../components/PageTitle/PageTitle.vue';
import Lucide from '../../base-components/Lucide/Lucide.vue';
import Loader from '../../components/Loader/Loader.vue';
import { computed, Ref, ref, toRaw, WritableComputedRef } from 'vue';
import ProductList from '../../components/productList/ProductList.vue';
import { IsProduct } from '../../interfaces/Products/is-product';
import { useProductsStore } from '../../stores/products-store';
import PreviewProduct from '../../components/PreviewProduct/PreviewProduct.vue';
import RefreshButton from '../../components/RefreshButton/RefreshButton.vue';
import router from '../../router';
import Button from '../../base-components/Button';
import Filter from '../../components/Filter/Filter.vue';
import { productFilterSettings } from '../../constants/filters';
import { IsFilterOption } from '../../components/Filter/interfaces/is-filter-option';
import { IsFilter } from '../../components/Filter/interfaces/is-filter';
import { IsFilterObject } from '../../interfaces/Is-filter-object';

// Stores
const productsStore = useProductsStore();
const productFilterStoreBE = useProductsStore();

// References
const showSettings = ref(false);
const productsListKey = ref(0);
const productPreviewKey = ref(0);
const filterExpanded = ref(false);
const spinning = ref(false);
const productFilter = ref(productFilterSettings);

// Computed
const selectedProduct: WritableComputedRef<IsProduct | undefined> = computed({
	get() {
		return productsStore.getSelectedProduct;
	},
	set(value) {
		productsStore.setSelectedProduct(value);
	}
});

// Functions
function openSettings(show: boolean) {
	showSettings.value = show;
}

async function productsLoaded() {
	// Preview first product if non is selected
	if (!selectedProduct.value) {
		const products = productsStore.getProducts;
		if (products.length > 0) {
			showPreview(products[0]);
		}
	}
	stopSpinning();
}

function stopSpinning() {
	spinning.value = false;
}

function startSpinning() {
	spinning.value = true;
}

function showPreview(product: IsProduct) {
	selectedProduct.value = undefined;

	setTimeout(() => {
		selectedProduct.value = product;
		// force rerender
		productPreviewKey.value += productPreviewKey.value;
	}, 10);
}

async function refreshProducts() {
	startSpinning();
	await productsStore.empty();
	selectedProduct.value = undefined;
	// Rerender component
	productsListKey.value += 1;
}

const filteredProducts: Ref<IsProduct[]> = ref([]);

function routeToAddProduct(): void {
	router.push({
		name: 'addProduct'
	});
}

async function filterProducts(filter: IsFilter): Promise<void> {
	// Create filter object for backend
	const selectedFilter: IsFilterObject = {
		nameOrPronkId: '',
		physicality: 'PHYSICAL',
		stockType: 'INSTANCED',
		unitType: 'SINGLE',
		channelName: 'ABN-AMRO',
		shopName: 'shop1'
	};

	// Set values
	filter.filterOptions.forEach(filterOption => {
		switch (filterOption.optionKey) {
			case 'nameOrPronkId':
				selectedFilter.nameOrPronkId = filterOption.selected;
				break;
			case 'stockType':
				selectedFilter.stockType = filterOption.selected;
				break;
			case 'physicality':
				selectedFilter.physicality = filterOption.selected;
				break;
			case 'unitType':
				selectedFilter.unitType = filterOption.selected;
				break;
		}
	});

	// Receive filtered objects
	filteredProducts.value = await productFilterStoreBE.productFilter(selectedFilter);
	console.log(filteredProducts.value);
}
</script>

<template>
	<!-- BEGIN: Title row	-->
	<div class="flex flex-row">
		<div class="grow -intro-x">
			<PageTitle>{{ $t('productsPage.pageTitle') }}</PageTitle>
		</div>
		<div class="mt-8 float-right">
			<div class="cursor-pointer" @click="openSettings(true)">
				<Lucide icon="Settings2" class="h-7 w-7"></Lucide>
			</div>
		</div>
	</div>
	<!-- END: Title row	-->

	<!-- BEGIN: Content -->
	<div class="flex-wrap xl:flex mt-5">
		<!-- BEGIN: Content left -->
		<div class="xl:w-8/12">
			<div class="full-height">
				<div class="intro-y mb-5">
					<h2 class="text-lg font-medium font-small p-2 pl-0">{{ $t('productsPage.filterTitle') }}</h2>
					<Filter :filter="productFilter" @filter-confirmed="filterProducts($event)" />
				</div>

				<div class="intro-y mt-5">
					<div class="flex justify-end">
						<div class="grow">
							<div class="flex w-100">
								<div>
									<h2 class="text-lg font-medium p-2 pl-0">{{ $t('productsPage.productListTitle') }}</h2>
								</div>
								<div class="pt-2">
									<RefreshButton :spinning="spinning" @click="refreshProducts()" />
								</div>
								<div class="pt-2 grow">
									<Button class="mb-2 mr-1 float-right" :variant="'primary'" size="sm" @click="routeToAddProduct">
										{{ $t('productsPage.add') }}
										<Lucide class="w-5 h-5" icon="Plus"></Lucide>
									</Button>
								</div>
							</div>
						</div>
					</div>

					<Suspense :key="productsListKey" @resolve="productsLoaded()">
						<ProductList
							class="products-list"
							:filterUpdated="filteredProducts"
							:class="[{ 'products-list-expanded-filter': filterExpanded }]"
							@clicked="showPreview"
						/>

						<template #fallback>
							<div class="flex grow justify-center">
								<div class="w-12">
									<Loader />
								</div>
							</div>
						</template>
					</Suspense>
				</div>
			</div>

			<!-- BEGIN: Details top < xl -->
			<div class="h-full xl:pr-0 mt-9 xl:hidden">
				<Suspense :key="productsListKey">
					<div v-if="selectedProduct" class="intro-x">
						<PreviewProduct :key="productPreviewKey" :product="selectedProduct" class="product-preview" />
					</div>

					<template #fallback>
						<div class="flex grow justify-center">
							<div class="w-12">
								<Loader />
							</div>
						</div>
					</template>
				</Suspense>
			</div>
			<!-- END: Details top < xl -->
		</div>
		<!-- END: Content left -->

		<!-- BEGIN: Details right >= xl -->
		<div class="xl:w-4/12 xl:pl-5 xl:pr-0 hidden xl:block">
			<div class="full-height pt-11">
				<Suspense :key="productsListKey">
					<div v-if="selectedProduct" class="intro-x">
						<PreviewProduct :key="productPreviewKey" :product="selectedProduct" class="product-preview" />
					</div>

					<template #fallback>
						<div class="flex grow justify-center">
							<div class="w-12">
								<Loader />
							</div>
						</div>
					</template>
				</Suspense>
			</div>
		</div>
		<!-- END: Details right >= xl -->
	</div>
	<!-- END: Content -->
</template>

<style scoped>
@media (min-width: 1280px) {
	.product-preview {
		height: calc(100vh - 260px);
		overflow-y: auto;
	}

	.products-list {
		height: calc(100vh - 430px);
		overflow-y: auto;
	}

	.full-height {
		overflow-y: hidden !important;
	}
}

.full-height {
	position: relative;
	width: 100%;
	height: calc(100vh - 220px);
	overflow-y: auto;
}
</style>
