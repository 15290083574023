import { Router, RouteLocationNormalizedLoaded } from 'vue-router';
import { Menu } from '../../stores/side-menu';
import { slideUp, slideDown } from '../../utils/helper';

interface Route extends RouteLocationNormalizedLoaded {
	forceActiveMenu?: string;
}

export interface FormattedMenu extends Menu {
	active?: boolean | undefined | "";
	activeDropdown?: boolean;
	subMenu?: FormattedMenu[];
}

// Setup side menu
const findActiveMenu = (subMenu: Menu[], route: Route): boolean => {
	let match = false;
	subMenu.forEach(item => {
		if (
			((route.forceActiveMenu !== undefined && item.pageName?.find(x => x === route.forceActiveMenu)) ||
				(route.forceActiveMenu === undefined && item.pageName === route.name)) &&
			!item.ignore
		) {
			match = true;
		} else if (!match && item.subMenu) {
			match = findActiveMenu(item.subMenu, route);
		}
	});
	return match;
};

const nestedMenu = (menu: Array<Menu | 'divider'>, route: Route) => {
	const formattedMenu: Array<FormattedMenu | 'divider'> = [];
	menu.forEach(item => {
		if (typeof item !== 'string') {
			const menuItem: FormattedMenu = {
				icon: item.icon,
				title: item.title,
				pageName: item.pageName,
				subMenu: item.subMenu,
				ignore: item.ignore
			};
			menuItem.active =
				((route.forceActiveMenu !== undefined && item.pageName?.find(x => x === route.forceActiveMenu)) ||
					(route.forceActiveMenu === undefined && item.pageName?.find(x => x === route.name)) ||
					(menuItem.subMenu && findActiveMenu(menuItem.subMenu, route))) &&
				!menuItem.ignore;

			if (menuItem.subMenu) {
				menuItem.activeDropdown = findActiveMenu(menuItem.subMenu, route);

				// Nested menu
				const subMenu: Array<FormattedMenu> = [];
				nestedMenu(menuItem.subMenu, route).map(menu => typeof menu !== 'string' && subMenu.push(menu));
				menuItem.subMenu = subMenu;
			}

			formattedMenu.push(menuItem);
		} else {
			formattedMenu.push(item);
		}
	});

	return formattedMenu;
};

const linkTo = (menu: FormattedMenu, router: Router) => {
	if (menu.subMenu) {
		menu.activeDropdown = !menu.activeDropdown;
	} else {
		if (menu.pageName !== undefined) {
			router.push({
				name: menu.pageName[0]
			});
		}
	}
};

const enter = (el: any) => {
	slideDown(el, 300);
};

const leave = (el: any) => {
	slideUp(el, 300);
};

export { nestedMenu, linkTo, enter, leave };
