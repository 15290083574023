<script setup lang="ts" async>
import PageTitle from '../../components/PageTitle/PageTitle.vue';
import { computed, ref, WritableComputedRef } from 'vue';
import Lucide from '../../base-components/Lucide/Lucide.vue';
import Loader from '../../components/Loader/Loader.vue';
import { useOrdersStore } from '../../stores/orders-store';
import RefreshButton from '../../components/RefreshButton/RefreshButton.vue';
import OrderList from '../../components/OrderList/OrderList.vue';
import { IsOrder } from '../../interfaces/orders/is-order';
import { Suspense } from 'vue';
import PreviewOrder from '../../components/PreviewOrder/PreviewOrder.vue';
import Filter from '../../components/Filter/Filter.vue';
import { orderFilterSettings } from '../../constants/filters';
import { IsFilterOption } from '../../components/Filter/interfaces/is-filter-option';

// Stores
const ordersStore = useOrdersStore();

// References
const showSettings = ref(false);
let spinning = ref(false);
const orderPreviewKey = ref(0);
const ordersListKey = ref(0);
const listActionsDisabled = ref(true);
const filterExpanded = ref(false);
const orderFilter = ref(orderFilterSettings);

// Computed
const selectedOrder: WritableComputedRef<IsOrder | undefined> = computed({
	get() {
		return ordersStore.getSelectedOrder;
	},
	set(value) {
		ordersStore.setSelectedOrder(value);
	}
});

// Functions
function openSettings(show: boolean) {
	showSettings.value = show;
}

function startSpinning() {
	spinning.value = true;
}

function stopSpinning() {
	spinning.value = false;
}

function showPreview(order: IsOrder) {
	selectedOrder.value = undefined;

	setTimeout(() => {
		selectedOrder.value = order;
		// force rerender
		orderPreviewKey.value += 1;
	}, 10);
}

async function refreshOrders() {
	startSpinning();
	await ordersStore.emptyState();
	selectedOrder.value = undefined;
	reRenderList();
}

async function ordersLoaded() {
	// Preview first order if non is selected
	if (!selectedOrder.value) {
		const orders = ordersStore.getOrders;
		if (orders.length > 0) {
			showPreview(orders[0]);
		}
	}
	stopSpinning();
}

function filterOn(filterOption: IsFilterOption) {
	console.log(filterOption);
}

function reRenderList() {
	ordersListKey.value += 1;
}
</script>

<template>
	<!-- BEGIN: Title row	-->
	<div class="flex flex-row">
		<div class="grow -intro-x">
			<PageTitle>{{ $t('ordersPage.pageTitle') }}</PageTitle>
		</div>
		<div class="grow intro-y">
			<div class="mt-8 float-right">
				<div class="cursor-pointer" @click="openSettings(true)">
					<Lucide icon="Settings2" class="h-7 w-7"></Lucide>
				</div>
			</div>
		</div>
	</div>
	<!-- END: Title row	-->

	<!-- BEGIN: Content -->
	<div class="flex-wrap xl:flex mt-5">
		<!-- BEGIN: Content left -->
		<div class="xl:w-7/12">
			<div class="full-height">
				<div class="intro-y mb-5">
					<h2 class="text-lg font-medium font-small p-2 pl-0">{{ $t('ordersPage.filterTitle') }}</h2>
					<Filter :filter="orderFilter" @filter-changed="filterOn($event)" />
				</div>

				<div class="intro-y mt-5">
					<div class="flex justify-end">
						<div class="grow">
							<div class="flex">
								<div>
									<h2 class="text-lg font-medium p-2 pl-0">{{ $t('ordersPage.orderListTitle') }}</h2>
								</div>
								<div class="pt-2">
									<RefreshButton :spinning="spinning" @click="refreshOrders()" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<Suspense :key="ordersListKey" @resolve="ordersLoaded()">
					<OrderList
						class="orders-list"
						:class="[{ 'orders-list-expanded-filter': filterExpanded }]"
						@clicked="showPreview($event)"
						@rerender="reRenderList()"
					/>

					<template #fallback>
						<div class="flex grow justify-center">
							<div class="w-12">
								<Loader />
							</div>
						</div>
					</template>
				</Suspense>
			</div>

			<!-- BEGIN: Details top < xl -->
			<div class="h-full xl:pr-0 mt-9 xl:hidden">
				<Suspense :key="ordersListKey">
					<div v-if="selectedOrder" class="intro-x">
						<PreviewOrder :key="orderPreviewKey" :order="selectedOrder" class="order-preview" />
					</div>
					<template #fallback>
						<div class="flex grow justify-center">
							<div class="w-12">
								<Loader />
							</div>
						</div>
					</template>
				</Suspense>
			</div>
			<!-- END: Details top < xl -->
		</div>
		<!-- END: Content left -->

		<!-- BEGIN: Details right >= xl -->
		<div class="xl:w-5/12 xl:pl-5 xl:pr-0 hidden xl:block">
			<div class="full-height pt-11">
				<Suspense :key="ordersListKey">
					<div v-if="selectedOrder" class="intro-x">
						<PreviewOrder :key="orderPreviewKey" :order="selectedOrder" class="order-preview" />
					</div>
					<template #fallback>
						<div class="flex grow justify-center">
							<div class="w-12">
								<Loader />
							</div>
						</div>
					</template>
				</Suspense>
			</div>
		</div>
		<!-- END: Details right >= xl -->
	</div>
	<!-- END: Content -->
</template>

<style scoped>
@media (min-width: 1280px) {
	.order-preview {
		height: calc(100vh - 260px);
		overflow-y: auto;
	}

	.orders-list {
		height: calc(100vh - 430px);
		overflow-y: auto;
	}

	.orders-list-expanded-filter {
		height: calc(100vh - 505px);
		overflow-y: auto;
	}

	.full-height {
		overflow-y: hidden !important;
	}
}

.full-height {
	position: relative;
	width: 100%;
	height: calc(100vh - 220px);
	overflow-y: auto;
}
</style>
