import { defineStore } from 'pinia';
import { Http } from '../../ts-modules/http-client-module';
import { IsHateoasResponse } from '../interfaces/hateoas/is-hateoas-response';
import { useApiStore } from './api-store';
import { PiniaSingleton } from '../singletons/pinia-singleton';
import { IsChannel } from '../interfaces/inventory/is-channel';
import { IsShop } from '../interfaces/inventory/is-shop';
import { IsAvailabilityRequest } from '../interfaces/inventory/is-availability-request';
import { IsAvailabilityResponse } from '../interfaces/inventory/is-availability-response';

interface IsInventoryState {
	managementEndpoint: URL;
	availabilityEndpoint: URL;
	shipmentEndpoint: URL;
	channelsState?: IsHateoasResponse;
	shopsState?: IsHateoasResponse;
}

const storeId: string = 'inventory';

const pinia = PiniaSingleton.getInstance();
const apiStore = useApiStore(pinia);
const api = apiStore.get;

const channelsEndpoint = '/channels';
const shopsEndpoint = '/shops';
const allocationsEndpoint = '/allocations?channels=';
const distributionEndpoint = '/distribution?skus=';
const predictEndpoint = '/predict';
const projectEndpoint = '/project';
const queryEndpoint = '/query';

export const useInventoryStore = defineStore(storeId, {
	state: (): IsInventoryState => ({
		managementEndpoint: new URL(`${api}/${import.meta.env.VITE_INVENTORY_MANAGEMENT_ENDPOINT}`),
		availabilityEndpoint: new URL(`${api}/${import.meta.env.VITE_INVENTORY_AVAILABILITY_ENDPOINT}`),
		shipmentEndpoint: new URL(`${api}/${import.meta.env.VITE_INVENTORY_SHIPMENTS_ENDPOINT}`)
	}),
	getters: {
		getChannels(): IsChannel[] {
			if (this.channelsState?._embedded?.channels) {
				return this.channelsState._embedded.channels;
			} else {
				return [];
			}
		},
		getShops(): IsShop[] {
			if (this.channelsState?._embedded?.shops) {
				return this.channelsState._embedded.shops;
			} else {
				return [];
			}
		}
	},
	actions: {
		downloadChannels(): Promise<IsChannel[]> {
			const endpoint = new URL(this.managementEndpoint.pathname + channelsEndpoint, this.managementEndpoint);
			return new Promise<IsChannel[]>(resolve => {
				Http.get(endpoint).then(response => {
					const body = response.body;
					this.channelsState = body;
					if (this.channelsState?._embedded?.channels) {
						resolve(this.channelsState._embedded.channels);
					}
				});
			});
		},
		downloadChannel(channelKey: string): Promise<IsChannel> {
			const endpoint = new URL(this.managementEndpoint.pathname + `${channelsEndpoint}/${channelKey}`, this.managementEndpoint);
			return new Promise<IsChannel>(resolve => {
				Http.get(endpoint).then(response => {
					const body = response.body;
					resolve(body);
				});
			});
		},
		downloadShops(): Promise<IsShop[]> {
			const endpoint = new URL(this.managementEndpoint.pathname + shopsEndpoint, this.managementEndpoint);
			return new Promise<IsShop[]>(resolve => {
				Http.get(endpoint).then(response => {
					const body = response.body;
					this.channelsState = body;
					if (this.channelsState?._embedded?.shops) {
						resolve(this.channelsState._embedded.shops);
					}
				});
			});
		},
		downloadShop(shopKey: string): Promise<IsShop> {
			const endpoint = new URL(this.managementEndpoint.pathname + `${shopsEndpoint}/${shopKey}`, this.managementEndpoint);
			return new Promise<IsShop>(resolve => {
				Http.get(endpoint).then(response => {
					const body = response.body;
					resolve(body);
				});
			});
		},
		downloadAllocations(channelKeys: string[]): Promise<any[]> {
			const endpoint = new URL(this.managementEndpoint.pathname + `${allocationsEndpoint}${channelKeys}`, this.managementEndpoint);
			return new Promise<any[]>(resolve => {
				Http.get(endpoint).then(response => {
					console.log(response);
					const body = response.body;
					resolve(body);
				});
			});
		},
		distribution(skus: string[]): Promise<any[]> {
			const endpoint = new URL(this.managementEndpoint.pathname + `${distributionEndpoint}${skus}`, this.managementEndpoint);
			return new Promise<any[]>(resolve => {
				Http.get(endpoint).then(response => {
					const body = response.body;
					resolve(body);
				});
			});
		},
		checkAvailability(availabilityRequest: IsAvailabilityRequest[]): Promise<IsAvailabilityResponse> {
			const endpoint = new URL(this.availabilityEndpoint.pathname + `${predictEndpoint}`, this.availabilityEndpoint);
			return new Promise<IsAvailabilityResponse>((resolve, reject) => {
				Http.post(endpoint, { requests: availabilityRequest })
					.then(response => {
						const body = response.body;
						resolve(body);
					})
					.catch(error => {
						reject(error);
					});
			});
		},
		checkProjectAvailability(availabilityRequest: IsAvailabilityRequest): Promise<IsAvailabilityResponse> {
			const endpoint = new URL(this.availabilityEndpoint.pathname + `${projectEndpoint}`, this.availabilityEndpoint);
			return new Promise<IsAvailabilityResponse>(resolve => {
				Http.post(endpoint, { requests: [availabilityRequest] }).then(response => {
					const body = response.body;
					resolve(body);
				});
			});
		},
		downloadShipmentsByChannel(channelKey: string): Promise<any[]> {
			const endpoint = new URL(this.shipmentEndpoint.pathname + queryEndpoint, this.shipmentEndpoint);
			return new Promise<any[]>(resolve => {
				Http.post(endpoint, {
					channelKey: channelKey
				}).then(response => {
					const body = response.body;
					resolve(body);
				});
			});
		},
		downloadShipmentsByShop(shopKey: string): Promise<any[]> {
			const endpoint = new URL(this.shipmentEndpoint.pathname + queryEndpoint, this.shipmentEndpoint);
			return new Promise<any[]>(resolve => {
				Http.post(endpoint, {
					shopKey: shopKey
				}).then(response => {
					const body = response.body;
					resolve(body);
				});
			});
		}
	}
});
