<script setup lang="ts" async>
import { OrderStatusEnum } from '../../interfaces/orders/order-status.enum';
import { useOrdersStore } from '../../stores/orders-store';
import { computed, ComputedRef, ref } from 'vue';
import { useRoute } from 'vue-router';
import PageTitle from '../../components/PageTitle';
import Button from '../../base-components/Button';
import { useSideMenuStore } from '../../stores/side-menu';
import router from '../../router';
import { IsOrder } from '../../interfaces/orders/is-order';
import { Tab } from '../../base-components/Headless';
import OrderRules from '../../components/OrderRules';
import AddressEditor from '../../components/AddressEditor';
import { copyWithoutReference } from '../../../ts-modules/utils-module/utils/copy-utils';
import OrderDetailsEditor from '../../components/orderDetailsEditor';
import OrderNotes from '../../components/OrderNotes/OrderNotes.vue';
import { IsNote } from '../../interfaces/orders/is-note';
import { Dialog } from '../../base-components/Headless';
import Lucide from '../../base-components/Lucide';
import { useDarkModeStore } from '../../stores/dark-mode';

// Tools
const route = useRoute();

// Stores
const ordersStore = useOrdersStore();
const sideMenuStore = useSideMenuStore();
const darkModeStore = useDarkModeStore();

// Variables
let originalOrder: IsOrder;

// References
const editedOrder = ref<IsOrder>();
const addressesKey = ref(0);
const detailsKey = ref(0);
const noOrderWarning = ref(false);

// Init logic
disableBoxedContentStyle(true);
loadOrder(route.query.orderNumber as string);

// Computes
const disabled: ComputedRef<boolean> = computed(() => {
	return editedOrder.value?.status !== OrderStatusEnum.BEING_EDITED;
});

const notes: ComputedRef<IsNote[]> = computed(() => {
	return editedOrder.value?.notes || [];
});

// Functions
async function loadOrder(orderNumber: string): Promise<void> {
	// eslint-disable-next-line no-async-promise-executor
	return new Promise<void>(async resolve => {
		let order: IsOrder | undefined;
		const selectedOrder = ordersStore.getSelectedOrder;

		// Get order
		if (selectedOrder?.orderNumber === orderNumber) {
			order = selectedOrder;
		} else {
			// Get all orders
			const orders = ordersStore.getOrders;
			if (orders.length > 0) {
				// Get specific order from cache
				order = orders.find(order => order.orderNumber === orderNumber) as IsOrder;
			} else {
				order = undefined;
			}

			if (!order) {
				try {
					// Get specific order from server
					order = await ordersStore.downloadOrder(orderNumber);
				} catch (e) {
					// Show no order warning and go back to orders page
					showNoOrderWarning(true);
					return;
				}
			}
		}

		// Load notes
		order.notes = await ordersStore.downloadNotes(orderNumber);
		// Set order as selected
		ordersStore.setSelectedOrder(order);

		// Copy order for editing
		originalOrder = copyWithoutReference(order);
		editedOrder.value = copyWithoutReference(order);

		resolve();
	});
}

function orderStatusChanged(status: OrderStatusEnum): void {
	// Apply change on server
	switch (status) {
		case OrderStatusEnum.BEING_EDITED:
			ordersStore
				.uploadOrderInEditMode(editedOrder.value as IsOrder)
				// Save local when succeeded
				.then(() => setEditedOrderStatus(status))
				.catch(e => console.log(e.message));
			break;
		default:
			ordersStore
				.uploadOrderInDoneMode(editedOrder.value as IsOrder)
				// Save local when succeeded
				.then(() => setEditedOrderStatus(status))
				.catch(e => console.log(e.message));
			break;
	}

	// TODO: DEV ONLY: Remove when backend PUT works
	setEditedOrderStatus(status);
}

function setEditedOrderStatus(status: OrderStatusEnum): void {
	if (editedOrder.value) {
		editedOrder.value.status = status;
	}
}

function saveOrderChanges(order: IsOrder | undefined): void {
	if (order) {
		originalOrder = copyWithoutReference(order);
		ordersStore.setSelectedOrder(copyWithoutReference(order));
		ordersStore.cacheState();
		reRender();
	}
}

function cancelOrderChanges(): void {
	if (editedOrder.value) {
		editedOrder.value = copyWithoutReference(originalOrder);
		ordersStore.setSelectedOrder(copyWithoutReference(editedOrder.value));
		reRender();
	}
}

function reRender(): void {
	reRenderAddresses();
	reRenderDetails();
}

function reRenderAddresses(): void {
	addressesKey.value++;
}

function reRenderDetails(): void {
	detailsKey.value++;
}

function disableBoxedContentStyle(disable: boolean): void {
	sideMenuStore.boxedContent = !disable;
	if (disable) {
		// Re-enable boxed content style when leaving page
		router.beforeEach(() => {
			sideMenuStore.boxedContent = true;
		});
	}
}

function addNote(note: string): void {
	const orderNumber = editedOrder.value?.orderNumber as string;
	ordersStore.uploadNewNote(note, orderNumber).then(() => {
		ordersStore.downloadNotes(orderNumber).then(notes => {
			if (editedOrder.value) {
				editedOrder.value.notes = notes;
			}
		});
	});
}

function showNoOrderWarning(value: boolean): void {
	noOrderWarning.value = value;
}
</script>

<template>
	<!-- BEGIN: Title row	-->
	<div class="flex flex-row">
		<div class="flex-1 -intro-x">
			<PageTitle>{{ $t('orderPage.pageTitle') }}</PageTitle>
		</div>

		<div v-if="editedOrder" class="grow intro-y">
			<div class="mt-8 float-right">
				<Button @click="saveOrderChanges(editedOrder)" variant="primary" class="w-24 mb-2 ml-4 float-right">
					{{ $t('orderPage.save') }}
				</Button>
				<Button @click="cancelOrderChanges()" variant="outline-primary" class="w-24 mb-2 ml-4 float-right">
					{{ $t('orderPage.cancel') }}
				</Button>
			</div>
		</div>
	</div>
	<!-- END: Title row	-->

	<!-- BEGIN: Editor boxes	-->
	<div v-if="editedOrder" class="grid grid-cols-12 gap-5 mt-3">
		<!-- BEGIN: Order details	-->
		<div class="col-span-12 xl:col-span-8 -intro-x box p-5">
			<h3 class="text-base font-medium">{{ $t('orderDetails.orderDetails') }}</h3>
			<OrderDetailsEditor
				v-if="editedOrder"
				:key="detailsKey"
				v-model="editedOrder"
				:disabled="disabled"
				@update:status="orderStatusChanged($event)"
			></OrderDetailsEditor>
		</div>
		<!-- END: Order details	-->

		<!-- BEGIN: Notes and documents	-->
		<div class="col-span-12 xl:col-span-4 intro-x box p-5">
			<!-- BEGIN: Notes	-->
			<div v-if="editedOrder">
				<OrderNotes :notes="notes" @add-note="addNote($event)" />
			</div>
			<!-- END: Notes	-->

			<!-- BEGIN: Documents	-->
			<div class="mt-4">
				<h3 class="text-base font-medium pb-2">{{ $t('orderDetails.documents') }}</h3>
				<span class="links">
					<a href="test.pdf" target="_blank">test.pdf</a>
				</span>
				<span class="pl-1 pr-1"> - </span>
				<span>
					<a href="test.xlsx" target="_blank">test.xlsx</a>
				</span>
			</div>
			<!-- END: Documents	-->
		</div>
		<!-- END: Notes and documents	-->

		<!-- BEGIN: Order rules	-->
		<div class="col-span-12 xl:col-span-8 intro-y box p-5">
			<h3 class="text-base font-medium">{{ $t('orderDetails.orderRules') }}</h3>
			<div v-if="editedOrder" class="pt-4">
				<OrderRules :order-lines="editedOrder.orderLines" />
			</div>
		</div>
		<!-- END: Order details	-->

		<!-- BEGIN: Addresses	-->
		<div class="col-span-12 xl:col-span-4 intro-y box p-5">
			<h3 class="text-base font-medium">{{ $t('orderDetails.addresses') }}</h3>

			<Tab.Group v-if="editedOrder" :key="addressesKey" class="pt-4">
				<Tab.List variant="tabs">
					<Tab v-for="address in editedOrder.addresses">
						<Tab.Button class="w-full py-2" as="button">{{ $t(`orderDetails.${address.type}`) }}</Tab.Button>
					</Tab>
				</Tab.List>
				<Tab.Panels class="border-b border-l border-r">
					<Tab.Panel v-for="(address, index) in editedOrder.addresses" :key="address.id" class="p-5">
						<AddressEditor v-model="editedOrder.addresses[index]" :disabled="disabled"></AddressEditor>
					</Tab.Panel>
				</Tab.Panels>
			</Tab.Group>
		</div>
		<!-- END: Addresses	-->
	</div>
	<!-- END: Editor boxes	-->

	<!-- BEGIN: No order warning -->
	<Dialog
		staticBackdrop
		:open="noOrderWarning"
		@close="
			() => {
				showNoOrderWarning(false);
			}
		"
	>
		<Dialog.Panel>
			<div class="p-5 text-center select-none">
				<Lucide icon="XCircle" class="w-16 h-16 mx-auto mt-3 text-warning" />
				<div class="mt-5 text-3xl">{{ $t(`orderDetails.sorry`) }}</div>
				<div class="mt-2 text-slate-500">
					{{ $t(`orderDetails.orderNotFound`) }}
				</div>
			</div>
			<div class="px-5 pb-8 text-center">
				<Button
					class="select-none"
					type="button"
					variant="primary"
					@click="
						() => {
							showNoOrderWarning(false);
							router.push({ name: 'orders' });
						}
					"
				>
					{{ $t(`orderDetails.backToOrders`) }}
				</Button>
			</div>
		</Dialog.Panel>
	</Dialog>
	<!-- END: No order warning -->
</template>
