<script setup lang='ts'>
import { useDarkModeStore } from '../../stores/dark-mode';
import LoadingIcon from '../../base-components/LoadingIcon/LoadingIcon.vue';
import { ref } from 'vue';

const darkModeStore = useDarkModeStore();
const isDark = darkModeStore.darkMode;

const defaultColor = '#1B253B';
const darkModeColor = '#CBD5E1';

const iconColor = ref(isDark ? darkModeColor : defaultColor);

</script>

<template>
	<div class='flex grow justify-center'>
		<div class='w-12'>
			<LoadingIcon :color='iconColor' icon='oval' />
		</div>
	</div>
</template>
