import { defineStore } from 'pinia';
import { Http } from '../../ts-modules/http-client-module';
import { WebStorage } from '../../ts-modules/storage-module';
import { useApiStore } from './api-store';
import { PiniaSingleton } from '../singletons/pinia-singleton';
import { IsProposal } from '../interfaces/proposals/is-proposal';
import { IsBulkProposal } from '../interfaces/proposals/is-bulk-proposal';

interface isBulkProposalState {
	apiEndpoint: URL;
	defaultState: IsProposal;
	currentState: IsProposal;
}

const storeId: string = 'bulk_proposal';
const defaultId: string = '_default';

const pinia = PiniaSingleton.getInstance();
const apiStore = useApiStore(pinia);
const api = apiStore.get;

const jsonServer = import.meta.env.VITE_API_JS;

export const useBulkProposalStore = defineStore(storeId, {
	state: (): isBulkProposalState => ({
		apiEndpoint: new URL(`${api}/${import.meta.env.VITE_BULK_PROPOSAL_ENDPOINT}`),
		defaultState: WebStorage.Default.getFromStorage(storeId + defaultId) || { proposals: [], total: 0, totalOrders: 0 },
		currentState: WebStorage.Default.getFromStorage(storeId) || { proposals: [], total: 0, totalOrders: 0 }
	}),
	getters: {
		get(state): IsProposal {
			return state.currentState;
		},
		getDefault(state): IsProposal {
			return state.defaultState;
		}
	},
	actions: {
		setProposals(bulkProposals: IsBulkProposal[]): void {
			this.currentState.proposals = bulkProposals;
		},
		setDefaultProposals(bulkProposals: IsBulkProposal[]): void {
			this.defaultState.proposals = [...bulkProposals];
		},
		async resetProposals(): Promise<void> {
			return new Promise<void>(async resolve => {
				await this.emptyState();
				await this.downloadProposals();
				await this.cacheState();
				resolve();
			});
		},
		async downloadProposals() {
			let res: any;
			if (apiStore.get === jsonServer) {
				res = await Http.get(this.apiEndpoint);
			} else {
				res = await Http.post(this.apiEndpoint, {});
			}
			this.currentState = res.body;
		},
		async cacheState() {
			WebStorage.Default.saveToStorage(storeId, this.currentState);
			WebStorage.Default.saveToStorage(storeId + defaultId, this.defaultState);
		},
		async emptyState() {
			this.currentState = { proposals: [], total: 0, totalOrders: 0 };
			WebStorage.Default.removeFromStorage(storeId);
		}
	}
});
