<script setup lang="ts">
import { FormInput, FormLabel, FormSelect } from '../../base-components/Form';
import { computed, ComputedRef, ref } from 'vue';
import { IsOrder } from '../../interfaces/orders/is-order';
import { dateToString } from '../../../ts-modules/utils-module/utils/date-utils';
import { OrderStatusEnum } from '../../interfaces/orders/order-status.enum';
import Litepicker from '../../base-components/Litepicker';

// Properties
const props = defineProps<{ modelValue: IsOrder; disabled: boolean }>();
const emits = defineEmits(['update:modelValue', 'update:status']);

// References
const order = ref<IsOrder>(props.modelValue);

// Computes
const orderDate: ComputedRef<string> = computed(() => {
	if (order.value) {
		const date = new Date(order.value.orderDate);
		return dateToString(date);
	} else {
		return '';
	}
});

const deliveryDate: ComputedRef<string> = computed(() => {
	if (order.value) {
		const date = new Date(order.value.deliveryDate);
		return dateToString(date);
	} else {
		return '';
	}
});

const statuses: ComputedRef<string[]> = computed(() => {
	return Object.values(OrderStatusEnum);
});

// Functions
function statusUpdated(status: string): void {
	emits('update:status', status);
}
</script>

<template>
	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('orderDetails.orderNumber') }}</FormLabel>
		<FormInput v-model="order.orderNumber" type="text" class="w-full" :disabled="true" />
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('orderDetails.store') }}</FormLabel>
		<FormInput v-model="order.shopKey" type="text" class="w-full" :disabled="disabled" />
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('orderDetails.shippingMethod') }}</FormLabel>
		<FormInput v-model="order.shippingMethod" :disabled="disabled" type="text" class="w-full" />
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('orderDetails.orderDate') }}</FormLabel>
		<FormInput v-if="disabled" type="text" class="w-full" disabled="true" />
		<Litepicker
			v-else
			v-model="orderDate"
			:options="{
				format: 'DD-MM-YYYY',
				startDate: orderDate,
				autoApply: false,
				singleMode: true,
				dropdowns: {
					minYear: 1990,
					maxYear: null,
					months: true,
					years: true
				}
			}"
		/>
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('orderDetails.deliveryDate') }}</FormLabel>
		<FormInput v-if="disabled" type="text" class="w-full" disabled="true" />
		<Litepicker
			v-else
			v-model="deliveryDate"
			:options="{
				format: 'DD-MM-YYYY',
				startDate: deliveryDate,
				autoApply: false,
				singleMode: true,
				dropdowns: {
					minYear: 1990,
					maxYear: null,
					months: true,
					years: true
				}
			}"
		/>
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('orderDetails.status') }}</FormLabel>
		<FormSelect v-model="order.status" @update:model-value="statusUpdated($event)">
			<option v-for="status in statuses" :key="status" :value="status">{{ $t('orderDetails.' + status + '') }}</option>
		</FormSelect>
	</div>
</template>
