<script setup lang="ts" async>
import PageTitle from '../../components/PageTitle';
import { copyWithoutReference } from '../../../ts-modules/utils-module/utils/copy-utils';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import Button from '../../base-components/Button';
import { useSideMenuStore } from '../../stores/side-menu';
import router from '../../router';
import { IsReceiving } from '../../interfaces/receivings/is-receiving';
import { useReceivingsStore } from '../../stores/receivings-store';
import ReceivingDetails from '../../components/ReceivingDetails/ReceivingDetails.vue';
import Litepicker from '../../base-components/Litepicker';
import OrderRules from '../../components/OrderRules/OrderRules.vue';

// Tools
const route = useRoute();

// Stores
const receivingsStore = useReceivingsStore();
const sideMenuStore = useSideMenuStore();

// Variables
let originalReceiving: IsReceiving;

// References
const editedReceiving = ref<IsReceiving | undefined>(receivingsStore.getSelectedReceiving);
const detailsKey = ref(0);

// Init logic
disableBoxedContentStyle(true);
loadReceiving(route.query.traceId as string);

// Functions
function disableBoxedContentStyle(disable: boolean): void {
	sideMenuStore.boxedContent = !disable;
	if (disable) {
		// Re-enable boxed content style when leaving page
		router.beforeEach(() => {
			sideMenuStore.boxedContent = true;
		});
	}
}

async function loadReceiving(traceId: string): Promise<void> {
	// eslint-disable-next-line no-async-promise-executor
	return new Promise<void>(async resolve => {
		let receiving: IsReceiving | undefined;
		const selectedReceiving = receivingsStore.getSelectedReceiving;

		// Get receiving
		if (selectedReceiving?.traceId === traceId) {
			receiving = selectedReceiving;
		} else {
			// Get all receivings
			const receivings = receivingsStore.getReceivings;
			if (receivings.length > 0) {
				// Get specific from cache
				receiving = receivings.find(receiving => receiving.traceId === traceId) as IsReceiving;
			} else {
				receiving = undefined;
			}
		}

		// Set as selected
		receivingsStore.setSelectedReceiving(receiving);

		// Copy for editing
		originalReceiving = copyWithoutReference(receiving);
		editedReceiving.value = copyWithoutReference(receiving);

		console.log(receiving);
		resolve();
	});
}

function reRender(): void {
	reRenderDetails();
}

function reRenderDetails(): void {
	detailsKey.value++;
}

function saveChanges(receiving: IsReceiving | undefined): void {
	if (receiving) {
		originalReceiving = copyWithoutReference(receiving);
		receivingsStore.setSelectedReceiving(copyWithoutReference(receiving));
		receivingsStore.cacheState();
		reRender();
	}
}

function cancelChanges(): void {
	if (editedReceiving.value) {
		editedReceiving.value = copyWithoutReference(originalReceiving);
		receivingsStore.setSelectedReceiving(copyWithoutReference(editedReceiving.value));
		reRender();
	}
}
</script>

<template>
	<div class="">
		<!-- BEGIN: Title row	-->
		<div class="flex flex-row">
			<div class="flex-1 -intro-x">
				<PageTitle>{{ $t('receivingPage.pageTitle') }}</PageTitle>
			</div>
			<div class="grow intro-y">
				<div class="mt-8 float-right">
					<Button @click="saveChanges(editedReceiving)" variant="primary" class="w-24 mb-2 ml-4 float-right">
						{{ $t('receivingPage.save') }}
					</Button>
					<Button @click="cancelChanges()" variant="outline-primary" class="w-24 mb-2 ml-4 float-right">
						{{ $t('receivingPage.cancel') }}
					</Button>
				</div>
			</div>
		</div>
		<!-- END: Title row	-->

		<!-- BEGIN: Editor boxes	-->
		<div class="grid grid-cols-12 gap-2 mt-3">
			<!-- BEGIN: Details	-->
			<div class="col-span-12 xl:col-span-8 -intro-x box p-5">
				<h3 class="text-base font-medium">{{ $t('receivingDetails.title') }}</h3>
				<ReceivingDetails v-if="editedReceiving" :key="detailsKey" v-model="editedReceiving"></ReceivingDetails>
			</div>
			<!-- END: Details	-->

			<!-- BEGIN: RequestLines	-->
			<div class="col-span-12 xl:col-span-4 intro-x">
				<OrderRules
					v-if="editedReceiving"
					:order-lines="editedReceiving.requestLines"
					:hide-by-name="['attributes']"
					:no-column-grow-by-name="['pronkId', 'quantity']"
				/>
			</div>
			<!-- END: RequestLines	-->
		</div>
	</div>
</template>
