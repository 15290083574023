<script setup lang="ts" async>
import PageTitle from '../../components/PageTitle/PageTitle.vue';
import BulkSettingsSlideOver from '../../components/BulkSettingsSlideOver/BulkSettingsSlideOver.vue';
import { computed, ref, watch } from 'vue';
import Lucide from '../../base-components/Lucide/Lucide.vue';
import Button from '../../base-components/Button';
import Loader from '../../components/Loader/Loader.vue';
import BulkQueue from '../../components/BulkQueue/BulkQueue.vue';
import { useDarkModeStore } from '../../stores/dark-mode';
import { copyWithoutReference } from '../../../ts-modules/utils-module/utils/copy-utils';
import RefreshButton from '../../components/RefreshButton';
import { useBulkQueueStore } from '../../stores/bulk-queue-store';
import BulkProposalList from '../../components/BulkProposalList/BulkProposalList.vue';
import { useBulkProposalStore } from '../../stores/bulk-proposal-store';
import { IsBulkProposal } from '../../interfaces/proposals/is-bulk-proposal';
import { useBulkStore } from '../../stores/bulk-store';
import { BulkStatus, IsBulk } from '../../interfaces/bulks/is-bulk';
import { IsFilterOption } from '../../components/Filter/interfaces/is-filter-option';
import Filter from '../../components/Filter/Filter.vue';
import BulkHistorySlideOver from '../../components/BulkHistorySlideOver/BulkHistorySlideOver.vue';
import { useBulkHistoryStore } from '../../stores/bulk-history-store';
import { useBulkHistorySlideOverStore } from '../../components/BulkHistorySlideOver/stores/bulk-history-slide-over-store';
import { bulkFilterSettings } from '../../constants/filters';

// Stores
const bulksProposalStore = useBulkProposalStore();
const bulkStore = useBulkStore();
const bulkQueueStore = useBulkQueueStore();
const darkModeStore = useDarkModeStore();
const bulkHistoryStore = useBulkHistoryStore();
const bulkHistorySlideOverStore = useBulkHistorySlideOverStore();

// References
const showSettings = ref(false);
const showBulkHistory = ref(bulkHistorySlideOverStore.get.visible);
const buttonKey = ref(0);
const spinning = ref(false);
const selectionButtonDisabled = ref(true);
const bulkListKey = ref(0);
const darkMode = ref(darkModeStore.darkMode);
const listActionsDisabled = ref(true);
const bulkHistory = ref(bulkHistoryStore.get);
const bulkFilter = ref(bulkFilterSettings);

// Init
setSelectionButtonStatus();
if (bulkQueueStore.get.length === 0) {
	bulkStore.downloadBulks().then(() => {
		const bulks = bulkStore.get;
		for (const bulk of bulks) {
			bulkQueueStore.add(bulk);
		}
		bulkQueueStore.cache();
	});
}

// Listeners
darkModeStore.$subscribe((event, value) => {
	// Set new state
	darkMode.value = value.darkModeValue;
	// Rerender button
	buttonKey.value += 1;
});

bulksProposalStore.$subscribe(() => {
	setSelectionButtonStatus();
});

// Listen for side over visibility changes
watch(showBulkHistory, value => {
	if (!value) {
		bulkHistorySlideOverStore.setVisibility(false);
	}
});

// Functions
function openSettings(show: boolean) {
	showSettings.value = show;
}

function openBulkHistory(show: boolean) {
	// Load history if not available and open slide over.
	if (bulkHistory.value.length < 1) {
		bulkHistoryStore.download().then(() => {
			bulkHistory.value = bulkHistoryStore.get;
			showBulkHistory.value = show;
			// Show slide over
			bulkHistorySlideOverStore.setVisibility(true);
		});
	} else {
		// Open slide over immediately.
		showBulkHistory.value = show;
	}
}

function setSelectionButtonStatus() {
	const bulkProposalSelection = getSelectedBulkProposals();
	selectionButtonDisabled.value = bulkProposalSelection.length < 1;
}

async function refreshBulkProposals() {
	startSpinning();
	await bulksProposalStore.emptyState();
	// Rerender component
	bulkListKey.value += 1;
}

function getSelectedBulkProposals(): IsBulkProposal[] {
	// Get bulk proposals from store
	const bulkProposals = bulksProposalStore.get;
	// Init selection array
	const selectedProposals = [];
	// Iterated over proposals
	for (const proposals of bulkProposals.proposals) {
		// Add selected once to selectedBulks array
		if (proposals.selected) {
			selectedProposals.push(proposals);
		}
	}
	return selectedProposals;
}

function addSelectionToQueue(bulkProposalSelections: IsBulkProposal[]): void {
	// Check if there is a selection
	if (bulkProposalSelections.length > 0) {
		// Copy the selection
		const copyBulkProposalSelections = copyWithoutReference(bulkProposalSelections);
		// Add every rule separately to queue
		for (const copyBulkProposalSelection of copyBulkProposalSelections) {
			// Create bulk
			const bulk: IsBulk = {
				bulkLines: [],
				createDate: new Date(),
				orderModels: [],
				status: BulkStatus.PENDING,
				size: copyBulkProposalSelection.deliver,
				uuid: copyBulkProposalSelection.footprint,
				packaging: copyBulkProposalSelection.packaging
			};

			bulkQueueStore.add(bulk);
		}
		// Save changes to cache
		bulkQueueStore.cache();
	}
}

function startSpinning() {
	spinning.value = true;
}

function stopSpinning() {
	spinning.value = false;
}

function filterOn(filterOption: IsFilterOption) {
	switch (filterOption.optionKey) {
		case 'dateRange':
			listActionsDisabled.value = !filterDateIsCurrentDate(filterOption);
			break;
		default:
			console.log(filterOption);
			break;
	}
}

function filterDateIsCurrentDate(filterDateRange: IsFilterOption): boolean {
	// Check input
	if (filterDateRange.startDate && filterDateRange.endDate) {
		// Get and convert dates
		const startDate = new Date(new Date(filterDateRange.startDate).toDateString());
		const endDate = new Date(new Date(filterDateRange.endDate).toDateString());
		const currentDate = new Date(new Date().toDateString());
		// Check if dates are equal with current date
		return startDate.toISOString() === currentDate.toISOString() && endDate.toISOString() === currentDate.toISOString();
	} else {
		return false;
	}
}
</script>

<template>
	<!-- BEGIN: Title row	-->
	<div class="flex flex-row">
		<div class="grow -intro-x">
			<PageTitle>Hal 1</PageTitle>
		</div>
		<div class="grow intro-y">
			<div class="mt-8 float-right">
				<div class="cursor-pointer" @click="openSettings(true)">
					<Lucide icon="Settings2" class="h-7 w-7"></Lucide>
				</div>
			</div>
		</div>
	</div>
	<!-- END: Title row	-->

	<!-- BEGIN: Content -->
	<div class="flex-wrap xl:flex mt-5">
		<!-- BEGIN: Content left -->
		<div class="xl:w-9/12">
			<div class="full-height">
				<div class="intro-y">
					<h2 class="text-lg font-medium font-small p-2 pl-0">{{ $t('bulksPage.filterTitle') }}</h2>
					<Filter :filter="bulkFilter" @filter-changed="filterOn($event)" />
				</div>

				<!-- BEGIN: Content top =< xl -->
				<div class="xl:hidden intro-y">
					<h2 class="text-lg font-medium p-2 pl-0">{{ $t('bulksPage.bulkQueue') }}</h2>

					<div class="relative">
						<div class="cursor-pointer absolute right-0 -top-8 z-50" @click="openBulkHistory(true)">
							<Lucide icon="History" class="h-7 w-7"></Lucide>
						</div>
					</div>

					<Suspense>
						<div class="bulk-queue dark:bg-slate-700">
							<BulkQueue />
						</div>

						<template #fallback>
							<div class="flex grow justify-center">
								<div class="w-12">
									<Loader />
								</div>
							</div>
						</template>
					</Suspense>
				</div>
				<!-- END: Content top =< xl -->

				<div class="intro-y">
					<div class="flex justify-end">
						<div class="grow">
							<div class="flex">
								<div>
									<h2 class="text-lg font-medium p-2 pl-0">{{ $t('bulksPage.bulkListTitle') }}</h2>
								</div>
								<div class="pt-2">
									<RefreshButton :spinning="spinning" @click="refreshBulkProposals()" />
								</div>
							</div>
						</div>
						<!-- BEGIN: Selection button => md	-->
						<div class="pt-2 hidden md:block">
							<Button
								:variant="darkMode ? 'secondary' : 'pending'"
								:key="buttonKey"
								class="mb-2 mr-1"
								@click="addSelectionToQueue(getSelectedBulkProposals())"
								:disabled="selectionButtonDisabled || listActionsDisabled"
							>
								{{ $t('bulksPage.selectionToQueueButton') }}
								<Lucide class="ml-1 w-5 h-5" icon="ChevronsRight"></Lucide>
							</Button>
						</div>
						<!-- END: Selection button => md	-->
					</div>

					<Suspense :key="bulkListKey" @resolve="stopSpinning()">
						<div class="bulk-list">
							<div class="xl:mb-48">
								<BulkProposalList :selectionDisabled="listActionsDisabled" />
							</div>
						</div>

						<template #fallback>
							<div class="flex grow justify-center">
								<div class="w-12">
									<Loader />
								</div>
							</div>
						</template>
					</Suspense>
				</div>
			</div>
		</div>
		<!-- END: Content left -->

		<!-- BEGIN: Content right => xl -->
		<div class="hidden xl:block xl:w-3/12 h-full xl:pl-5 xl:pr-0 intro-x">
			<div class="full-height">
				<div class="mb-2 mt-2">
					<span class="text-lg font-medium p-2 pl-0">{{ $t('bulksPage.bulkQueue') }}</span>
					<span class="float-right">
						<div class="cursor-pointer" @click="openBulkHistory(true)">
							<Lucide icon="History" class="h-7 w-7"></Lucide>
						</div>
					</span>
				</div>

				<Suspense>
					<div class="bulk-queue dark:bg-slate-700">
						<div class="xl:mb-10">
							<BulkQueue />
						</div>
					</div>

					<template #fallback>
						<div class="flex grow justify-center">
							<div class="w-12">
								<Loader />
							</div>
						</div>
					</template>
				</Suspense>
			</div>
		</div>
		<!-- END: Content right => xl -->
	</div>
	<!-- END: Content -->

	<!-- BEGIN: Selection button =< md	-->
	<div class="fixed bottom-3 right-1 z-50 md:hidden">
		<Button
			:variant="darkMode ? 'secondary' : 'pending'"
			:key="buttonKey"
			@click="addSelectionToQueue(getSelectedBulkProposals())"
			:disabled="selectionButtonDisabled"
		>
			{{ $t('bulksPage.selectionToQueueButton') }}
			<Lucide class="ml-1 w-5 h-5" icon="ChevronsRight"></Lucide>
		</Button>
	</div>
	<!-- END: Selection button =< md	-->

	<!-- BEGIN: Page settings slide over -->
	<BulkSettingsSlideOver v-model="showSettings"></BulkSettingsSlideOver>
	<!-- END: Page settings slide over -->

	<!-- BEGIN: Bulk history slide over -->
	<BulkHistorySlideOver v-model="showBulkHistory" v-model:bulk-history="bulkHistory"></BulkHistorySlideOver>
	<!-- END: Bulk history slide over -->
</template>

<style scoped>
@media (min-width: 1280px) {
	.bulk-queue {
		height: calc(100vh - 220px);
		overflow-y: auto;
	}

	.bulk-list {
		height: calc(100vh - 430px);
		overflow-y: auto;
	}

	.full-height {
		position: relative;
		width: 100%;
		height: calc(100vh - 220px);
		overflow-y: hidden;
	}
}

.bulk-queue {
	box-shadow: 0px 3px 20px #0000000b;
	background-color: white;
	border-color: transparent;
	border-radius: 0.375rem;
	position: relative;
	min-height: 10vh;
}
</style>
