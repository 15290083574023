<script setup lang="ts">
import { PackageJsonUtils } from '../../../ts-modules/utils-module';
import { ref, watch } from 'vue';
import DevToolsSlideOver from '../DevToolsSlideOver/DevToolsSlideOver.vue';
import { useDarkModeStore } from '../../stores/dark-mode';
import { WebStorage } from '../../../ts-modules/storage-module';

// Web storage keys
const versionKey = 'version';

// Stores
const darkModeStore = useDarkModeStore();

// References
const showDevTools = ref(false);
const devTools = ref();
const darkMode = ref(darkModeStore.darkMode);

// Listen for dark mode changes
darkModeStore.$subscribe((event, value) => {
	darkMode.value = value.darkModeValue;
});

// Get version number
const currentVersion = PackageJsonUtils.getVersion();
// Check version number in web storage
const previousVersion = WebStorage.LocalStorage.getFromStorage(versionKey);

// Remove cache and reload if version is updated
if (currentVersion !== previousVersion) {
	// Remove cache
	WebStorage.SessionStorage.clearStorage();
	WebStorage.LocalStorage.clearStorage();
	// Save current version
	WebStorage.LocalStorage.saveToStorage(versionKey, currentVersion);
	// Reload page
	location.reload();
}
// Set click count ref
const clickCount = ref(0);
// Watch clicks on version number
watch(clickCount, () => {
	// Open mode after 5 clicks
	if (clickCount.value >= 5) {
		clickCount.value = 0;
		showDevTools.value = true;
	}
});
</script>

<template>
	<!-- BEGIN: Footer -->
	<div class="relative">
		<div class="text-slate-400 m-1 mr-2 select-none absolute right-5 bottom-0">
			<span v-if="darkMode" class="cursor-pointer select-none" @click="clickCount++"> v{{ currentVersion }} </span>

			<span v-else> v{{ currentVersion }} </span>
		</div>
	</div>
	<!-- END: Footer -->

	<!-- BEGIN: Page settings slide over -->
	<DevToolsSlideOver :ref="devTools" v-model="showDevTools"></DevToolsSlideOver>
	<!-- END: Page settings slide over -->
</template>
