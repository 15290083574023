<script setup lang="ts">
import router from '../../router';
import { IsProduct } from '../../interfaces/Products/is-product';
import Lucide from '../../base-components/Lucide/Lucide.vue';
import ProductNotes from '../ProductNotes/ProductNotes.vue';
import Button from '../../base-components/Button';

let props = defineProps<{
	product: IsProduct;
}>();

// Functions
function routeToProductDetails(pronkId: string): void {
	router.push({
		name: 'product',
		query: {
			pronkId
		}
	});
}
</script>

<template>
	<div class="paper dark:bg-slate-700 break-words">
		<!-- BEGIN: Grid -->
		<div class="grid grid-cols-12">
			<!-- BEGIN: Row -->
			<div class="col-span-6 pl-5 pt-6 pb-3">
				<h2 class="text-lg font-medium pb-0">
					{{ props.product.pronkId }}
				</h2>
			</div>
			<div class="col-span-6 text-right pt-5 pr-5 pb-3">
				<Button @click="routeToProductDetails(props.product.pronkId)" variant="secondary">
					<Lucide icon="Info" class="w-5 h-5" />
				</Button>
			</div>
			<!-- END: Row -->

			<!-- BEGIN: Row -->
			<div class="col-span-12 border-t-2 border-slate-200 rounded ml-3 mr-3">
				<!-- Border only -->
			</div>
			<!-- END: Row -->

			<!-- BEGIN: Row -->
			<div class="col-span-12 p-5">
				<div>
					{{ $t('productDetails.description') }}:
					{{ props.product.attributes.DESCRIPTION?.value }}
				</div>
				<div>
					{{ $t('productDetails.dimensions') }}:
					{{ props.product.attributes.DIMENSIONS?.value }}
				</div>
				<div>
					{{ $t('productDetails.volume') }}:
					{{ props.product.attributes.VOLUME?.value }}
				</div>
				<div>
					{{ $t('productDetails.weight') }}:
					{{ props.product.attributes.WEIGHT?.value }}
				</div>
				<div>
					{{ $t('productDetails.price') }}:
					{{ props.product.attributes.PRICE?.value }}
				</div>
			</div>
			<!-- END: Row -->

			<!-- BEGIN: Row -->
			<div class="col-span-12 border-t-2 border-slate-200 rounded ml-3 mr-3">
				<!-- Border only -->
			</div>

			<!-- BEGIN: Row -->
			<div class="col-span-12 p-5">
				<div class="pb-2">
					<ProductNotes :product-notes="props.product.productNotes" />
				</div>
			</div>
			<!-- END: Row -->
		</div>
		<!-- END: Grid -->
	</div>
</template>

<style scoped>
.paper {
	box-shadow: 0px 3px 20px #0000000b;
	background-color: white;
	border-color: transparent;
	border-radius: 0.375rem;
	position: relative;
	min-height: 10vh;
}
</style>
