<script setup lang="ts">
import { IsOrder } from '../../interfaces/orders/is-order';
import { getReceiverAddress } from '../../utils/order-utils';
import { computed, ComputedRef } from 'vue';
import { IsAddress } from '../../interfaces/orders/is-address';
import OrderRules from '../OrderRules/OrderRules.vue';
import Button from '../../base-components/Button';
import Lucide from '../../base-components/Lucide';
import router from '../../router';

// Properties
let props = defineProps<{
	order: IsOrder;
}>();

// Computes
const client: ComputedRef<IsAddress | undefined> = computed(() => {
	return getReceiverAddress(props.order);
});

const receiver: ComputedRef<IsAddress | undefined> = computed(() => {
	return client.value;
});

// Functions
function routeToOrderDetails(orderNumber: string): void {
	router.push({
		name: 'order',
		query: {
			orderNumber
		}
	});
}
</script>

<template>
	<div class="paper dark:bg-slate-700 break-words">
		<!-- BEGIN: Grid -->
		<div class="grid grid-cols-12">
			<!-- BEGIN: Row -->
			<div class="col-span-6 pl-5 pt-6 pb-3">
				<h2 class="text-lg font-medium pb-0">
					{{ $t('orderDetails.orderTitle') }}:
					{{ props.order.orderNumber }}
				</h2>
			</div>

			<div class="col-span-6 text-right pt-5 pr-5 pb-3">
				<Button @click="routeToOrderDetails(props.order.orderNumber)" variant="secondary">
					<Lucide icon="Info" class="w-5 h-5" />
				</Button>
			</div>
			<!-- END: Row -->

			<!-- BEGIN: Row -->
			<div class="col-span-12 border-t-2 border-slate-500 rounded ml-3 mr-3">
				<!-- Border only -->
			</div>
			<!-- END: Row -->

			<!-- BEGIN: Row -->
			<div class="col-span-12 p-5">
				<div>
					{{ receiver?.firstName + ' ' + receiver?.lastName }}
				</div>
				<div>
					{{ receiver?.street + ' ' + receiver?.houseNumber }}
				</div>
				<div>
					{{ receiver?.postalCode + ' ' + receiver?.city + ', ' + receiver?.countryCode }}
				</div>
				<div>
					{{ $t('orderDetails.phone') }}:
					{{ receiver?.phone }}
				</div>
				<div class="wrap">
					{{ $t('orderDetails.email') }}:
					{{ receiver?.email }}
				</div>
			</div>
			<!-- END: Row -->

			<!-- BEGIN: Row -->
			<div class="col-span-12 border-t-2 border-slate-200 rounded ml-3 mr-3">
				<!-- Border only -->
			</div>
			<!-- END: Row -->

			<!-- BEGIN: Row -->
			<div class="col-span-12 p-5">
				<div class="pb-2">
					<OrderRules :order-lines="props.order.orderLines" :hide-by-name="['deliveredQuantity', 'eanCode']" :no-column-grow-by-name="['sku', 'quantity']" />
				</div>
			</div>
			<!-- END: Row -->
		</div>
		<!-- END: Grid -->
	</div>
</template>

<style scoped>
.paper {
	box-shadow: 0px 3px 20px #0000000b;
	background-color: white;
	border-color: transparent;
	border-radius: 0.375rem;
	position: relative;
	min-height: 10vh;
}
</style>
