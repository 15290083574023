<script setup lang="ts">
import { Icon } from '../../base-components/Lucide';
import Lucide from '../../base-components/Lucide/Lucide.vue';
import { computed } from 'vue';
import { BulkStatus, IsBulk } from '../../interfaces/bulks/is-bulk';

// Properties
const props = defineProps<{ modelValue: IsBulk }>();

// Emitters
const emit = defineEmits(['deleteFromQueue', 'printDocuments', 'printLabels']);

// Computed
const totalDelivery = computed(() => {
	return props.modelValue.size;
});

// Functions
function getStatusIconName(statusName: BulkStatus): Icon {
	switch (statusName) {
		case BulkStatus.PRINTED:
			return 'Printer';
		case BulkStatus.COMPLETED:
			return 'CheckCircle';
		case BulkStatus.COMPLETED_WITH_ERRORS:
			return 'MinusCircle';
		case BulkStatus.FAILED:
			return 'XCircle';
		case BulkStatus.PROCESSING:
			return 'Loader';
		default:
			return 'Hourglass';
	}
}

function showPrintIcons(status: BulkStatus): boolean {
	switch (status) {
		case BulkStatus.COMPLETED:
			return true;
		case BulkStatus.COMPLETED_WITH_ERRORS:
			return true;
		default:
			return false;
	}
}

function showDeleteIcon(status: BulkStatus): boolean {
	return status !== BulkStatus.PROCESSING;
}
</script>

<template>
	<div class="box pb-2 pt-2 mb-1">
		<div class="h-14">
			<div class="inline-block w-1/4 h-full relative">
				<div class="absolute left-0 top-1/3 w-full">
					<div class="flex justify-center">
						<Lucide
							:class="{ 'animate-slowSpin': getStatusIconName(props.modelValue.status) === 'Loader' }"
							:icon="getStatusIconName(props.modelValue.status)"
						></Lucide>
					</div>
				</div>
			</div>
			<div class="inline-block w-2/4 h-full">
				<div class="text-left">
					<div class="text-base font-medium truncate">{{ props.modelValue.uuid }}</div>
					<div class="mt-1 text-slate-500 truncate">
						{{ totalDelivery }}
						{{ $t('bulkOrderQueueCard.' + props.modelValue.status) }}
					</div>
				</div>
			</div>
			<div class="inline-block w-1/4 h-full relative">
				<div class="text-center absolute left-0 top-1/3 w-full">
					<div v-if="showPrintIcons(props.modelValue.status)" class="flex justify-center">
						<span class="pr-3 cursor-pointer" @click="emit('printDocuments', props.modelValue)"><Lucide icon="FileText"></Lucide></span>
						<span class="cursor-pointer" @click="emit('printLabels', props.modelValue)"><Lucide icon="Tag"></Lucide></span>
					</div>
					<div v-else class="flex justify-end">
						<span v-if="showDeleteIcon(props.modelValue.status)" @click="emit('deleteFromQueue')" class="pr-3 cursor-pointer">
							<Lucide icon="X"></Lucide
						></span>
						<span v-else class="pr-3 text-slate-500 truncate cursor-auto"><Lucide icon="X"></Lucide></span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped></style>
