import { IsHateoasResponse } from '../interfaces/hateoas/is-hateoas-response';
import { IsProductNote } from '../interfaces/Products/is-product-note';
import { PiniaSingleton } from '../singletons/pinia-singleton';
import { useApiStore } from './api-store';
import { defineStore } from 'pinia';
import { WebStorage } from '../../ts-modules/storage-module';
import { Http } from '../../ts-modules/http-client-module';

interface isProductNotesState {
	apiEndpoint: URL;
	defaultState: IsHateoasResponse;
	currentState: IsHateoasResponse;
	selectedProduct: IsProductNote | undefined;
}

const storeId: string = 'notes';
const defaultId: string = '_default';
const selectedId: string = '_selected';

const pinia = PiniaSingleton.getInstance();
const apiStore = useApiStore(pinia);
const api = apiStore.get;

export const useProductNotesStore = defineStore(storeId, {
	state: (): isProductNotesState => ({
		apiEndpoint: new URL(`${api}/${import.meta.env.VITE_NOTES_ENDPOINT}`),
		defaultState: WebStorage.Default.getFromStorage(storeId + defaultId),
		currentState: WebStorage.Default.getFromStorage(storeId),
		selectedProduct: WebStorage.Default.getFromStorage(storeId + selectedId)
	}),
	getters: {
		getNotes(state) {
			if (state.currentState) {
				const embedded = state.currentState._embedded;
				if (embedded) {
					console.log('Embedded products:', embedded.notes);
					return embedded.notes ? embedded.notes : [];
				}
			}
			return [];
		},
		getOriginals(state): IsProductNote[] {
			if (this.defaultState) {
				const embedded = state.defaultState._embedded;
				if (embedded) {
					return embedded.notes ? embedded.notes : [];
				}
			}
			return [];
		}
	},
	actions: {
		setProductNotes(notes: IsProductNote[]): void {
			if (this.currentState) {
				const embedded = this.currentState._embedded;
				if (embedded) {
					embedded.notes = [...notes];
				}
			}
		},
		async getProductNotes(productId: string): Promise<IsProductNote[]> {
			const endpoint = new URL(this.apiEndpoint.toString());
			endpoint.pathname = `shop-module/notes/product/${productId}`;
			const { body } = await Http.get(endpoint);
			console.log('Embedded notes:', body._embedded.notes);

			const notes: IsProductNote[] = body._embedded.notes.map((note: any) => {
				const lastModifiedDate = new Date(note.lastModifiedDate);
				const date = lastModifiedDate.toISOString().split('T')[0];
				return {
					pronkId: note.productId,
					notes: note.note,
					lastModifiedDate: date
				};
			});
			return notes;
		}
	}
});
