<script setup lang="ts" async>
import PageTitle from '../../components/PageTitle/PageTitle.vue';
import { computed, ref, WritableComputedRef } from 'vue';
import Lucide from '../../base-components/Lucide/Lucide.vue';
import Loader from '../../components/Loader/Loader.vue';
import RefreshButton from '../../components/RefreshButton/RefreshButton.vue';
import { Suspense } from 'vue';
import Filter from '../../components/Filter/Filter.vue';
import { receivingsFilterSettings } from '../../constants/filters';
import { IsFilterOption } from '../../components/Filter/interfaces/is-filter-option';
import { useReceivingsStore } from '../../stores/receivings-store';
import { IsReceiving } from '../../interfaces/receivings/is-receiving';
import ReceivingsList from '../../components/ReceivingsList/ReceivingsList.vue';
import PreviewReceiving from '../../components/PreviewReceiving/PreviewReceiving.vue';

// Stores
const receivingsStore = useReceivingsStore();

// References
const showSettings = ref(false);
let spinning = ref(false);
const receivingPreviewKey = ref(0);
const receivingsListKey = ref(0);
const filterExpanded = ref(false);
const receivingsFilter = ref(receivingsFilterSettings);

// Computed
const selectedReceiving: WritableComputedRef<IsReceiving | undefined> = computed({
	get() {
		return receivingsStore.getSelectedReceiving;
	},
	set(value) {
		receivingsStore.setSelectedReceiving(value);
	}
});

// Functions
function openSettings(show: boolean) {
	showSettings.value = show;
}

function startSpinning() {
	spinning.value = true;
}

function stopSpinning() {
	spinning.value = false;
}

function showPreview(receiving: IsReceiving) {
	selectedReceiving.value = undefined;

	setTimeout(() => {
		selectedReceiving.value = receiving;
		// force rerender
		receivingPreviewKey.value += 1;
	}, 10);
}

async function refreshReceiving() {
	startSpinning();
	await receivingsStore.emptyState();
	selectedReceiving.value = undefined;
	reRenderList();
}

async function receivingsLoaded() {
	// Preview first receiving if non is selected
	if (!selectedReceiving.value) {
		const receivings = receivingsStore.getReceivings;
		if (receivings.length > 0) {
			showPreview(receivings[0]);
		}
	}
	stopSpinning();
}

function filterOn(filterOption: IsFilterOption) {
	console.log(filterOption);
}

function reRenderList() {
	receivingsListKey.value += 1;
}
</script>

<template>
	<!-- BEGIN: Title row	-->
	<div class="flex flex-row">
		<div class="grow -intro-x">
			<PageTitle>{{ $t('receivingsPage.pageTitle') }}</PageTitle>
		</div>
		<div class="grow intro-y">
			<div class="mt-8 float-right">
				<div class="cursor-pointer" @click="openSettings(true)">
					<Lucide icon="Settings2" class="h-7 w-7"></Lucide>
				</div>
			</div>
		</div>
	</div>
	<!-- END: Title row	-->

	<!-- BEGIN: Content -->
	<div class="flex-wrap xl:flex mt-5">
		<!-- BEGIN: Content left -->
		<div class="xl:w-7/12">
			<div class="full-height">
				<div class="intro-y mb-5">
					<h2 class="text-lg font-medium font-small p-2 pl-0">{{ $t('receivingsPage.filterTitle') }}</h2>
					<Filter :filter="receivingsFilter" @filter-changed="filterOn($event)" />
				</div>

				<div class="intro-y mt-5">
					<div class="flex justify-end">
						<div class="grow">
							<div class="flex">
								<div>
									<h2 class="text-lg font-medium p-2 pl-0">{{ $t('receivingsPage.receivingListTitle') }}</h2>
								</div>
								<div class="pt-2">
									<RefreshButton :spinning="spinning" @click="refreshReceiving()" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<Suspense :key="receivingsListKey" @resolve="receivingsLoaded()">
					<ReceivingsList
						class="list"
						:class="[{ 'list-expanded-filter': filterExpanded }]"
						@clicked="showPreview($event)"
						@rerender="reRenderList()"
					/>

					<template #fallback>
						<div class="flex grow justify-center">
							<div class="w-12">
								<Loader />
							</div>
						</div>
					</template>
				</Suspense>
			</div>

			<!-- BEGIN: Details top < xl -->
			<div class="h-full xl:pr-0 mt-9 xl:hidden">
				<Suspense :key="receivingsListKey">
					<div v-if="selectedReceiving" class="intro-x">
						<PreviewReceiving :key="receivingPreviewKey" :receiving="selectedReceiving" class="preview" />
					</div>
					<template #fallback>
						<div class="flex grow justify-center">
							<div class="w-12">
								<Loader />
							</div>
						</div>
					</template>
				</Suspense>
			</div>
			<!-- END: Details top < xl -->
		</div>
		<!-- END: Content left -->

		<!-- BEGIN: Details right >= xl -->
		<div class="xl:w-5/12 xl:pl-5 xl:pr-0 hidden xl:block">
			<div class="full-height pt-11">
				<Suspense :key="receivingsListKey">
					<div v-if="selectedReceiving" class="intro-x">
						<PreviewReceiving :key="receivingPreviewKey" :receiving="selectedReceiving" class="preview" />
					</div>
					<template #fallback>
						<div class="flex grow justify-center">
							<div class="w-12">
								<Loader />
							</div>
						</div>
					</template>
				</Suspense>
			</div>
		</div>
		<!-- END: Details right >= xl -->
	</div>
	<!-- END: Content -->
</template>

<style scoped>
@media (min-width: 1280px) {
	.preview {
		height: calc(100vh - 260px);
		overflow-y: auto;
	}

	.list {
		height: calc(100vh - 430px);
		overflow-y: auto;
	}

	.list-expanded-filter {
		height: calc(100vh - 505px);
		overflow-y: auto;
	}

	.full-height {
		overflow-y: hidden !important;
	}
}

.full-height {
	position: relative;
	width: 100%;
	height: calc(100vh - 220px);
	overflow-y: auto;
}
</style>
