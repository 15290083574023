import { defineStore } from 'pinia';
import { WebStorage } from '../../ts-modules/storage-module';

export enum ApiType {
	production = import.meta.env.VITE_API_PROD,
	develop = import.meta.env.VITE_API_DEV,
	mockServer = import.meta.env.VITE_API_MOCK
}

interface ApiStoreState {
	apiValue: ApiType;
}

const storeId: string = 'apiStore';

export const useApiStore = defineStore(storeId, {
	state: (): ApiStoreState => ({
		apiValue: WebStorage.Default.getFromStorage(storeId) ? WebStorage.Default.getFromStorage(storeId) : ApiType.mockServer
	}),
	getters: {
		get(state) {
			return state.apiValue;
		}
	},
	actions: {
		setApi(state: ApiType) {
			WebStorage.Default.saveToStorage(storeId, state);
			this.apiValue = state;
		}
	}
});
