import { createPinia, Pinia } from 'pinia';

export class PiniaSingleton {
	private static instance: Pinia;

	public static getInstance(): Pinia {
		if (!PiniaSingleton.instance) {
			PiniaSingleton.instance = createPinia();
		}

		return PiniaSingleton.instance;
	}
}
