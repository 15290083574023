import { defineStore } from 'pinia';
import { WebStorage } from '../../ts-modules/storage-module';
import { IsBulk } from '../interfaces/bulks/is-bulk';

const storeId = 'bulkQueue';
const storage = sessionStorage;

interface BulkQueueState {
	bulkQueueValue: IsBulk[];
}

export const useBulkQueueStore = defineStore(storeId, {
	state: (): BulkQueueState => ({
		bulkQueueValue: JSON.parse(storage.getItem(storeId) || '[]')
	}),
	getters: {
		get(state): IsBulk[] {
			return state.bulkQueueValue;
		}
	},
	actions: {
		set(bulkQueueValue: IsBulk[]) {
			this.bulkQueueValue = bulkQueueValue;
		},
		add(bulk: IsBulk) {
			// Add created to queue
			this.bulkQueueValue.push(bulk);
		},
		remove(index: number): void {
			this.bulkQueueValue.splice(index, 1);
		},
		cache() {
			WebStorage.Default.saveToStorage(storeId, this.bulkQueueValue);
		}
	}
});
