import { defineStore } from 'pinia';
import { InteractionRequiredAuthError, LogLevel, PublicClientApplication, SilentRequest } from '@azure/msal-browser';
import * as msal from '@azure/msal-browser';
import { IsAccount } from '../interfaces/account/IsAccount';

interface isAuthState {
	msalInstance: PublicClientApplication;
	loginRequest: {
		scopes: string[];
		extraScopesToConsent?: string[];
	};
	homeId?: string;
}

const storeId: string = 'auth';

export const useAuthStore = defineStore(storeId, {
	state: (): isAuthState => ({
		msalInstance: new msal.PublicClientApplication({
			auth: {
				clientId: '74054367-3717-44ee-9269-a490f7ab31b0',
				authority: 'https://login.microsoftonline.com/76e6af65-d23f-424e-9d14-abde6a3c1d82'
			},
			cache: {
				cacheLocation: 'sessionStorage'
			},
			system: {
				loggerOptions: {
					loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
						if (containsPii) {
							return;
						}
						switch (level) {
							case LogLevel.Error:
								console.error(message);
								return;
							case LogLevel.Info:
								console.info(message);
								return;
							case LogLevel.Verbose:
								console.debug(message);
								return;
							case LogLevel.Warning:
								console.warn(message);
								return;
						}
					},
					piiLoggingEnabled: false
				}
			}
		}),
		loginRequest: {
			scopes: ['User.Read', 'Mail.Read']
		},
		homeId: ''
	}),
	getters: {
		getAccountId(state): string | undefined {
			return this.homeId;
		}
	},
	actions: {
		async loginPopup() {
			try {
				const loginResponse = await this.msalInstance.loginPopup(this.loginRequest);
				this.homeId = loginResponse.account?.homeAccountId;
			} catch (e) {
				console.log(e);
			}
		},
		async logoutPopup() {
			if (this.homeId) {
				const logoutRequest = {
					account: this.msalInstance.getAccountByHomeId(this.homeId)
				};
				await this.msalInstance.logoutPopup(logoutRequest);
			} else {
				await this.msalInstance.logoutPopup();
			}
		},
		async loginRedirect() {
			try {
				this.msalInstance.handleRedirectPromise().then(response => {
					if (response !== null) {
						this.homeId = response.account?.homeAccountId;
						// Display signed-in user content, call API, etc.
					} else {
						// In case multiple accounts exist, you can select
						const currentAccounts = this.msalInstance.getAllAccounts();

						if (currentAccounts.length === 0) {
							// no accounts signed-in, attempt to sign a user in
							this.msalInstance.loginRedirect(this.loginRequest);
						} else {
							this.homeId = currentAccounts[0].homeAccountId;
						}
					}
				});
			} catch (e) {
				console.log(e);
			}
		},
		async logoutRedirect() {
			if (this.homeId) {
				const logoutRequest = {
					account: this.msalInstance.getAccountByHomeId(this.homeId)
				};
				await this.msalInstance.logoutRedirect(logoutRequest);
			} else {
				await this.msalInstance.logoutRedirect();
			}
		},
		async getAccessToken(): Promise<string> {
			return new Promise<any>(async (resolve, error) => {
				if (this.homeId) {
					const silentRequest = {
						scopes: ['api://c5e86094-b04c-40a9-ae40-cbab40aa6f6c/.default'],
						account: this.msalInstance.getAccountByHomeId(this.homeId)
					} as SilentRequest;

					this.msalInstance
						.acquireTokenSilent(silentRequest)
						.then(tokenResponse => {
							resolve(tokenResponse.accessToken);
						})
						.catch(e => {
							if (e instanceof InteractionRequiredAuthError) {
								// Show error
								console.log(e);
								alert(e.errorMessage);
							}
							error(e);
						});
				}
			});
		},
		getAccountDetails(): IsAccount {
			if (this.homeId) {
				const account = this.msalInstance.getAccountByHomeId(this.homeId);
				const accountDetails = {
					name: account?.name,
					username: account?.username,
					avatar: '/defaultAvatarSmall.png'
				} as IsAccount;
				return accountDetails;
			} else {
				return {} as IsAccount;
			}
		},
		async getAccountPicture(): Promise<void> {
			const token = `Bearer ${await this.getAccessToken()}`;
			fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
				headers: { Authorization: token }
			}).then(response => console.log(response.blob()));
		}
	}
});
