import { defineStore } from 'pinia';
import { IsSort } from '../interfaces/is-sort';
import { SortDirection } from '../../../../ts-modules/utils-module/utils/sort-utils';

interface IsSortState {
	sorts: Map<string, IsSort>;
}

const storeId: string = 'Sort';

export const useSortStore = defineStore(storeId, {
	state: (): IsSortState => ({
		sorts: new Map()
	}),
	actions: {
		getSort(listKey: string): IsSort | undefined {
			const sort = this.sorts.get(listKey);
			return sort;
		},
		setSort(listKey: string, columnTitle: string, sortDirection: SortDirection): void {
			const sort: IsSort = {
				listKey,
				columnTitle,
				sortDirection
			};
			this.sorts.set(listKey, sort);
		},
		deleteSort(listKey: string): void {
			this.sorts.delete(listKey);
		}
	}
});
