<script setup lang="ts">
import BulkQueueCard from '../BulkQueueCard/BulkQueueCard.vue';
import { ref } from 'vue';
import Draggable from 'vuedraggable';
import { useBulkQueueStore } from '../../stores/bulk-queue-store';
import { BulkStatus, IsBulk } from '../../interfaces/bulks/is-bulk';

// Variables
const bulkQueueStore = useBulkQueueStore();
const bulkQueue = ref(bulkQueueStore.get);
const drag = false;
const dragKey = ref(0);

// Functions
function saveChange() {
	bulkQueueStore.set(bulkQueue.value);
	bulkQueueStore.cache();
}

function deleteFromQueue(queueIndex: number) {
	bulkQueueStore.remove(queueIndex);
	bulkQueueStore.cache();
}

function changeStatusToPrinted(bulk: IsBulk) {
	bulk.status = BulkStatus.PRINTED;
	bulkQueueStore.cache();
}
</script>

<template>
	<div class="box h-full">
		<Draggable :key="dragKey" draggable=".draggable" ghostClass="ghost" v-model="bulkQueue" group="bulks" itemKey="id" @change="saveChange()">
			<template #item="{ element, index }">
				<BulkQueueCard
					:class="{ draggable: element.status === 'PENDING', 'cursor-grab': element.status === 'PENDING' }"
					v-model="bulkQueue[index]"
					@deleteFromQueue="deleteFromQueue(index)"
					@printDocuments="changeStatusToPrinted($event)"
					@printLabels="changeStatusToPrinted($event)"
				/>
			</template>
		</Draggable>
	</div>
</template>

<style>
.ghost {
	@apply bg-slate-200 !important;
	color: transparent !important;

	.text-slate-500 {
		color: transparent !important;
	}
}
</style>
