<script setup lang="ts">
import Lucide from '../../base-components/Lucide/Lucide.vue';
import { useSortStore } from './stores/sort-store';
import { SortDirection, sortObjects, sortObjectsOnSubObjectsArray } from '../../../ts-modules/utils-module/utils/sort-utils';
import { ref } from 'vue';

interface SortProps {
	listKey: string; // Unique string to identify list
	columnTitle: string; // Up to 2 levels is possible (e.g. addresses.lastName)
	unsortedArray: any[]; // The list to sort
	align?: 'center' | 'left' | 'right'; // Alignment of the column row title
}

const props = defineProps<SortProps>();
const emit = defineEmits(['sorted']);

// Stores
const sortStore = useSortStore();

// References
let currentSort = ref(sortStore.getSort(props.listKey));
// Init
if (currentSort.value !== undefined) {
	if (currentSort.value.columnTitle === props.columnTitle) {
		sort(props.unsortedArray, currentSort.value.columnTitle, currentSort.value.sortDirection);
	}
} else {
	currentSort.value = {
		listKey: props.listKey,
		columnTitle: props.columnTitle,
		sortDirection: undefined
	};
}

function sort(array: any[], sortOn: string, sortDirection: SortDirection | undefined): void {
	// Init sorted list
	let sorted: any[];
	// Check if sort direction is set
	if (sortDirection !== undefined) {
		// Check for multiple sort levels
		if (sortOn.includes('.')) {
			// Separate property names
			const objectProperties = sortOn.split('.');
			// Sort on second level
			sorted = sortObjectsOnSubObjectsArray(array, objectProperties[0], objectProperties[1], sortDirection);
		} else {
			// Sort on first level
			sorted = sortObjects(array, sortOn, sortDirection);
		}
		// Safe current sort
		sortStore.setSort(props.listKey, sortOn, sortDirection);
		currentSort.value = sortStore.getSort(props.listKey);
	} else {
		// Remove current sort
		sortStore.deleteSort(props.listKey);
		currentSort.value = sortStore.getSort(props.listKey);
		// Reset default sort order
		sorted = props.unsortedArray;
	}

	emit('sorted', sorted);
}

function getNewSortDirection(sortDirection: SortDirection | undefined): SortDirection | undefined {
	switch (sortDirection) {
		case undefined:
			return SortDirection.ASC;
		case SortDirection.ASC:
			return SortDirection.DESC;
		case SortDirection.DESC:
			return undefined;
	}
}
</script>

<template>
	<div
		class="flex"
		:class="{ 'justify-center': props.align === 'center', 'justify-begin': props.align === 'left', 'justify-end': props.align === 'right' }"
		@click="sort(props.unsortedArray, props.columnTitle, getNewSortDirection(currentSort ? currentSort.sortDirection : undefined))"
	>
		<div class="font-medium whitespace-nowrap">
			{{ $t(`sorts.${listKey}.${props.columnTitle}`) }}
		</div>
		<div v-if="currentSort">
			<Lucide
				v-if="currentSort.columnTitle === props.columnTitle && currentSort.sortDirection === SortDirection.ASC"
				class="w-5 h-5"
				icon="SortAsc"
			></Lucide>
			<Lucide
				v-else-if="currentSort.columnTitle === props.columnTitle && currentSort.sortDirection === SortDirection.DESC"
				class="w-5 h-5"
				icon="SortDesc"
			></Lucide>
		</div>
	</div>
</template>

<style scoped></style>
