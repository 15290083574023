import { IsFilter } from '../../components/Filter/interfaces/is-filter';

const bulkFilterSettings = {
	filterKey: 'bulkFilter',
	showCompleteFilter: false,
	amountOfNeverHidingFilters: 3,
	maxRowColumns: 3,
	filterOptions: [
		{
			optionKey: 'shopKeys',
			label: 'filter.shopKeys',
			optionType: 'select',
			selected: '',
			options: [1, 2, 3]
		},
		{
			optionKey: 'pronkIds',
			label: 'filter.pronkIds',
			optionType: 'select',
			selected: '',
			options: [3, 2, 1]
		},
		{
			optionKey: 'countryCodes',
			label: 'filter.countryCodes',
			optionType: 'select',
			selected: '',
			options: ['NL', 'ES']
		},
		{
			optionKey: 'carrier',
			label: 'filter.carrier',
			optionType: 'select',
			selected: '',
			options: ['POST NL', 'DHL']
		},
		{
			optionKey: 'dateRange',
			label: 'filter.date',
			optionType: 'date',
			dateType: 'text',
			value: '-',
			startDate: new Date(Date.now()),
			endDate: new Date(Date.now())
		},
		{
			optionKey: 'search',
			label: 'filter.search',
			optionType: 'input',
			dateType: 'text',
			value: ''
		}
	]
} as IsFilter;
const bulkHistoryFilterSettings = {
	filterKey: 'bulkFilterHistory',
	showCompleteFilter: false,
	amountOfNeverHidingFilters: 3,
	maxRowColumns: 3,
	cssClasses: 'bg-slate-300 dark:bg-slate-900',
	filterOptions: [
		{
			optionKey: 'shopKeys',
			label: 'filter.shopKeys',
			optionType: 'select',
			selected: '',
			options: [1, 2, 3]
		},
		{
			optionKey: 'pronkIds',
			label: 'filter.pronkIds',
			optionType: 'select',
			selected: '',
			options: [3, 2, 1]
		},
		{
			optionKey: 'countryCodes',
			label: 'filter.countryCodes',
			optionType: 'select',
			selected: '',
			options: ['NL', 'ES']
		},
		{
			optionKey: 'carrier',
			label: 'filter.carrier',
			optionType: 'select',
			selected: '',
			options: ['POST NL', 'DHL']
		},
		{
			optionKey: 'dateRange',
			label: 'filter.date',
			optionType: 'date',
			dateType: 'text',
			value: '-',
			startDate: new Date(Date.now()),
			endDate: new Date(Date.now())
		},
		{
			optionKey: 'search',
			label: 'filter.search',
			optionType: 'input',
			dateType: 'text',
			value: ''
		}
	]
} as IsFilter;
const orderFilterSettings = {
	filterKey: 'orderFilter',
	showCompleteFilter: false,
	amountOfNeverHidingFilters: 2,
	maxRowColumns: 2,
	filterOptions: [
		{
			optionKey: 'shops',
			label: 'filter.shops',
			optionType: 'select',
			selected: 'Alle',
			options: ['Alle', 'VattenFall']
		},
		{
			optionKey: 'search',
			label: 'filter.search',
			placeholder: 'ordersFilter.search',
			dateType: 'text',
			optionType: 'input'
		},
		{
			optionKey: 'dateRange',
			label: 'filter.date',
			optionType: 'date',
			dateType: 'text',
			value: '-',
			startDate: new Date(Date.now()),
			endDate: new Date(Date.now())
		}
	]
} as IsFilter;
const productFilterSettings = {
	filterKey: 'productFilter',
	showCompleteFilter: false,
	amountOfNeverHidingFilters: 3,
	maxRowColumns: 3,
	enableButtons: true,
	filterOptions: [
		{
			optionKey: 'Product',
			label: 'productsFilter.pronkId',
			placeholder: 'productsFilter.pronkId',
			dateType: 'text',
			optionType: 'input'
		},
		{
			optionKey: 'channel',
			label: 'productsFilter.channel',
			optionType: 'select',
			selected: 'ABN-AMRO',
			options: ['ABN-AMRO', 'FNV', 'VATTENFALL', 'Randstad', 'Vumc']
		},
		{
			optionKey: 'shop',
			label: 'productsFilter.shop',
			optionType: 'select',
			selected: 'shop1',
			options: ['shop1', 'shop2', 'shop3', 'shop4', 'shop5']
		},
		{
			optionKey: 'stockType',
			label: 'productsFilter.stockType',
			optionType: 'select',
			selected: 'INSTANCED',
			options: ['INSTANCED', 'PHYSICAL']
		},
		{
			optionKey: 'physicality',
			label: 'productsFilter.physicality',
			optionType: 'select',
			selected: 'PHYSICAL',
			options: ['PHYSICAL', 'DIGITAL']
		},
		{
			optionKey: 'unitType',
			label: 'productsFilter.unitType',
			optionType: 'select',
			selected: 'SINGLE',
			options: ['SINGLE', 'COMPOSITE', 'CARTON']
		}
	]
} as IsFilter;

const receivingsFilterSettings = {
	filterKey: 'receivingsFilter',
	showCompleteFilter: false,
	amountOfNeverHidingFilters: 2,
	maxRowColumns: 2,
	filterOptions: [
		{
			optionKey: 'shops',
			label: 'filter.shops',
			optionType: 'select',
			selected: 'Alle',
			options: ['Alle', 'VattenFall']
		},
		{
			optionKey: 'chanel',
			label: 'filter.chanel',
			optionType: 'select',
			selected: 'Alle',
			options: ['Alle', '1', '2', '3']
		},
		{
			optionKey: 'status',
			label: 'filter.status',
			optionType: 'select',
			selected: 'Open',
			options: ['Open', 'Voltooid']
		},
		{
			optionKey: 'search',
			label: 'filter.search',
			placeholder: 'ordersFilter.search',
			dateType: 'text',
			optionType: 'input'
		}
	]
} as IsFilter;

export { bulkFilterSettings, orderFilterSettings, bulkHistoryFilterSettings, productFilterSettings, receivingsFilterSettings };
