import { defineStore } from 'pinia';
import { IsFootprintDetail } from '../../../interfaces/proposals/is-footprint-detail';

interface isFootprintDetailsVisibleState {
	footprintDetailsVisible: boolean;
	footprintDetails: IsFootprintDetail;
}

const storeId: string = 'FootprintDetailsSlideOverStore';
export const useFootprintDetailsSlideOverStore = defineStore(storeId, {
	state: (): isFootprintDetailsVisibleState => ({
		footprintDetailsVisible: false,
		footprintDetails: {
			footprint: '',
			orderNumbers: []
		}
	}),
	getters: {
		get(state): isFootprintDetailsVisibleState {
			return state;
		}
	},
	actions: {
		setFootprintDetailsVisible(visible: boolean): void {
			this.footprintDetailsVisible = visible;
		},
		setFootprintDetails(footprintDetails: IsFootprintDetail): void {
			this.footprintDetails = footprintDetails;
		},
		resetFootprintDetails(): void {
			this.footprintDetails = {
				footprint: '',
				orderNumbers: []
			};
		}
	}
});
