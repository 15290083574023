<script setup lang="ts">
// Needed for select box !!!
import FormCheck from '../../base-components/Form/FormCheck/';
import { ref } from 'vue';
import Sort from '../Sort';
import { NumberUtils } from '../../../ts-modules/utils-module';
import { useReceivingsStore } from '../../stores/receivings-store';
import { IsReceiving } from '../../interfaces/receivings/is-receiving';
import { dateToString } from '../../../ts-modules/utils-module/utils/date-utils';

// Stores
const receivingsStore = useReceivingsStore();

// Properties
const emit = defineEmits<{
	(e: 'clicked', receiving: IsReceiving): void;
	(e: 'rerender'): void;
}>();

// References
const receivings = ref(receivingsStore.getReceivings);
const unsortedReceivings = ref([...receivings.value]);
const listKey = 'receivingList';

// Init logic
if (receivings.value.length < 1) {
	// Get receivings
	await receivingsStore.resetReceiving();
	const receivingsFormServer = receivingsStore.getReceivings;
	// Save states
	receivingsStore.cacheState();
	// Show receivings
	receivings.value = receivingsFormServer;
	// Reset list
	forceListRerender();
}

// Functions
function forceListRerender(): void {
	emit('rerender');
}

function isEven(number: number): boolean {
	return NumberUtils.isEven(number);
}

function receivingClicked(receiving: IsReceiving): void {
	emit('clicked', receiving);
}
</script>

<template>
	<div class="box break-words absolute h-full w-full overflow-x-hidden">
		<!-- BEGIN: Table head -->
		<div class="flex flex-row p-3 pl-5 font-medium bg-slate-300 dark:bg-slate-900 rounded-t sticky z-50 top-0">
			<div class="sm:hidden">
				{{ $t('receivingList.mobileTitle') }}
			</div>
			<div class="hidden sm:block flex-none w-32 pr-5">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'deliveryDate'"
					:unsorted-array="unsortedReceivings"
					@sorted="receivings = $event"
				></Sort>
			</div>
			<div class="hidden sm:block flex-auto w-32 pr-5">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'traceId'"
					:unsorted-array="unsortedReceivings"
					@sorted="receivings = $event"
				></Sort>
			</div>
			<div class="hidden sm:block flex-auto w-32 pr-5">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'status'"
					:unsorted-array="unsortedReceivings"
					@sorted="receivings = $event"
				></Sort>
			</div>
			<div class="hidden sm:block flex-auto w-32 pr-5">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'type'"
					:unsorted-array="unsortedReceivings"
					@sorted="receivings = $event"
				></Sort>
			</div>
			<div class="hidden sm:block flex-auto w-32 pr-5">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="'orderedFor'"
					:unsorted-array="unsortedReceivings"
					@sorted="receivings = $event"
				></Sort>
			</div>
		</div>
		<!-- END: Table head -->

		<!-- BEGIN: Table content -->
		<div
			v-for="(receiving, index) in receivings"
			:key="receiving.traceId"
			class="flex flex-row p-3 pl-5 cursor-pointer intro-x"
			:class="[
				{ 'bg-white hover:bg-slate-300 dark:bg-slate-700 dark:hover:bg-slate-600': !isEven(index) },
				{ 'bg-secondary hover:bg-slate-300 dark:bg-slate-800 dark:hover:bg-slate-600': isEven(index) },
				{ 'rounded-b': receivings.length - 1 === index }
			]"
			@click="receivingClicked(receiving)"
		>
			<!-- BEGIN: Column 1 -->
			<div class="sm:pl-3 pb-2 sm:pb-0 relative z-0 flex-none w-32 pr-5">
				<div class="sm:-left-3 z-0">
					<div class="sm:hidden font-bold">{{ $t(`sorts.${listKey}.deliveryDate`) }}</div>
					<div class="ellipsis">{{ dateToString(new Date(receiving.deliveryDate)) }}</div>
				</div>
			</div>
			<!-- END: Column 1 -->

			<!-- BEGIN: Column 2 -->
			<div class="pb-2 sm:pb-1 flex-auto w-32 pr-5">
				<div class="sm:hidden font-bold">{{ $t(`sorts.${listKey}.traceId`) }}</div>
				<div>{{ receiving.traceId }}</div>
			</div>
			<!-- END: Column 2 -->

			<!-- BEGIN: Column 3 -->
			<div class="pb-2 pt-1 sm:pb-1 flex-auto w-32 pr-5">
				<div class="sm:hidden font-bold">{{ $t(`sorts.${listKey}.status`) }}:</div>
				<div>{{ receiving.status }}</div>
			</div>
			<!-- END: Column 3 -->

			<!-- BEGIN: Column 4 -->
			<div class="pt-1 pb-1 flex-auto w-32 pr-5">
				<div class="sm:hidden font-bold">{{ $t(`sorts.${listKey}.type`) }}:</div>
				<div>{{ receiving.type }}</div>
			</div>
			<!-- End: Column 4 -->

			<!-- BEGIN: Column 5 -->
			<div class="pt-1 pb-1 flex-auto w-32 pr-5">
				<div class="sm:hidden font-bold">{{ $t(`sorts.${listKey}.orderedFor`) }}:</div>
				<div>{{ receiving.orderedFor }}</div>
			</div>
			<!-- End: Column 5 -->
		</div>
		<!-- END: Table content -->
	</div>
</template>

<style scoped>
/* Disable number input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Disable number input arrows for Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
</style>
