{
  "bulk": "Bulk orders",
  "inventory": "Inventory",
  "bulkOrdersPage": {
    "title": "Bulk orders",
    "queue": "Queue"
  },
  "inventoryPage": {
    "title": "Inventory management"
  },
  "bulkOrdersTable": {
    "id": "Id",
    "orderDate": "Order date",
    "ordered": "Ordered",
    "stock": "Stock",
    "sendingMethod": "Sending method",
    "country": "Country",
    "content": "Content"
  }
}