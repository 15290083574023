<script setup lang="ts">
import { useRoute } from 'vue-router';
import Divider from './Divider.vue';
import Menu from './Menu.vue';
import TopBar from '../../components/TopBar';
import MobileMenu from '../../components/MobileMenu';
import { useSideMenuStore } from '../../stores/side-menu';
import { FormattedMenu, nestedMenu, enter, leave } from './side-menu';
import { watch, reactive, computed, onMounted, ref } from 'vue';
import { useAppConfigStore } from '../../stores/app-config';
import Footer from '../../components/Footer';

const hiddenMenuTitles = ref(false);

const appConfigStore = useAppConfigStore();
const appName = computed(() => appConfigStore.appName);
const appLogo = computed(() => appConfigStore.appAppLogoPath);

const route = useRoute();
let formattedMenu = reactive<Array<FormattedMenu | 'divider'>>([]);
const setFormattedMenu = (computedFormattedMenu: Array<FormattedMenu | 'divider'>) => {
	Object.assign(formattedMenu, computedFormattedMenu);
};
const sideMenuStore = useSideMenuStore();
const sideMenu = computed(() => nestedMenu(sideMenuStore.menu, route));

const boxedContent = computed(() => sideMenuStore.boxedContent);

watch(sideMenu, () => {
	setFormattedMenu(sideMenu.value);
});

function onLogoClick(): void {
	hiddenMenuTitles.value = !hiddenMenuTitles.value;
}

onMounted(() => {
	setFormattedMenu(sideMenu.value);
});
</script>

<template>
	<div class="py-2">
		<MobileMenu />
		<div class="flex mt-[4.7rem] md:mt-0">
			<!-- BEGIN: Side Menu -->
			<nav class="pr-5 pb-16 overflow-x-hidden hidden md:block w-[85px]" :class="{ 'xl:w-[200px]': !hiddenMenuTitles }">
				<div class="cursor-pointer flex items-center pt-4 pl-5 intro-x" @click="onLogoClick()">
					<img :alt="appName" class="w-10" :src="appLogo" />
					<span class="hidden ml-3 text-lg text-white" :class="{ 'xl:block': !hiddenMenuTitles }"> {{ appName }} </span>
				</div>
				<Divider type="div" class="my-6"></Divider>
				<ul>
					<!-- BEGIN: First Child -->
					<template v-for="(menu, menuKey) in formattedMenu">
						<Divider
							v-if="menu == 'divider'"
							type="li"
							:class="[
								'my-6',

								// Animation
								`opacity-0 animate-[0.4s_ease-in-out_0.1s_intro-divider] animate-fill-mode-forwards animate-delay-${(menuKey + 1) * 10}`
							]"
							:key="'divider-' + menuKey"
						></Divider>
						<li v-else :key="menuKey">
							<Menu
								:class="{
									// Animation
									[`opacity-0 translate-x-[50px] animate-[0.4s_ease-in-out_0.1s_intro-menu] animate-fill-mode-forwards animate-delay-${
										(menuKey + 1) * 10
									}`]: !menu.active
								}"
								:menu="menu"
								:formattedMenuState="[formattedMenu, setFormattedMenu]"
								level="first"
								:hideTitle="hiddenMenuTitles"
							></Menu>
							<!-- BEGIN: Second Child -->
							<Transition @enter="enter" @leave="leave">
								<ul v-if="menu.subMenu && menu.activeDropdown" class="rounded-lg bg-black/10 dark:bg-darkmode-900/30">
									<li v-for="(subMenu, subMenuKey) in menu.subMenu" :key="subMenuKey">
										<Menu
											:class="{
												// Animation
												[`opacity-0 translate-x-[50px] animate-[0.4s_ease-in-out_0.1s_intro-menu] animate-fill-mode-forwards animate-delay-${
													(subMenuKey + 1) * 10
												}`]: !subMenu.active
											}"
											:menu="subMenu"
											:formattedMenuState="[formattedMenu, setFormattedMenu]"
											level="second"
											:hideTitle="hiddenMenuTitles"
										></Menu>
										<!-- BEGIN: Third Child -->
										<Transition @enter="enter" @leave="leave" v-if="subMenu.subMenu">
											<ul v-if="subMenu.subMenu && subMenu.activeDropdown" class="rounded-lg bg-black/10 dark:bg-darkmode-900/30">
												<li v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu" :key="lastSubMenuKey">
													<Menu
														:class="{
															// Animation
															[`opacity-0 translate-x-[50px] animate-[0.4s_ease-in-out_0.1s_intro-menu] animate-fill-mode-forwards animate-delay-${
																(lastSubMenuKey + 1) * 10
															}`]: !lastSubMenu.active
														}"
														:menu="lastSubMenu"
														:formattedMenuState="[formattedMenu, setFormattedMenu]"
														level="third"
														:hideTitle="hiddenMenuTitles"
													></Menu>
												</li>
											</ul>
										</Transition>
										<!-- END: Third Child -->
									</li>
								</ul>
							</Transition>
							<!-- END: Second Child -->
						</li>
					</template>
					<!-- END: First Child -->
				</ul>
			</nav>
			<!-- END: Side Menu -->
			<!-- BEGIN: Content -->
			<div
				:class="{ 'content-box': boxedContent }"
				class="relative rounded-[30px] min-w-0 min-h-screen flex-1 pb-10 bg-slate-100 dark:bg-darkmode-700 px-4 md:px-[22px] max-w-full md:max-w-auto before:content-[''] before:w-full before:h-px before:block"
			>
				<TopBar></TopBar>
				<RouterView />
				<div class="absolute w-full left-0 bottom-0">
					<Footer />
				</div>
			</div>
			<!-- END: Content -->
		</div>
	</div>
</template>

<style scoped>
@media (min-width: 1280px) {
	.content-box {
		min-height: 0;
		height: calc(100vh - 40px);
	}
}
</style>
