<script setup lang="ts">
import { FormInput, FormLabel } from '../../base-components/Form';
import { ref } from 'vue';
import { IsProduct } from '../../interfaces/Products/is-product';

// Properties
const props = defineProps<{ modelValue: IsProduct; disabled?: boolean }>();

// References
const product = ref<IsProduct>(props.modelValue);
</script>

<template>
	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('productDetails.pronkId') }}</FormLabel>
		<FormInput v-model="product.pronkId" type="text" class="w-full" :disabled="props.disabled" />
	</div>
	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('productDetails.productName') }}</FormLabel>
		<FormInput v-model="product.name" type="text" class="w-full" :disabled="props.disabled" />
	</div>
	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('productDetails.unitType') }}</FormLabel>
		<FormInput v-model="product.unitType" type="text" class="w-full" :disabled="props.disabled" />
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('productDetails.stockType') }}</FormLabel>
		<FormInput v-model="product.stockType" type="text" class="w-full" :disabled="props.disabled" />
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('productDetails.physicality') }}</FormLabel>
		<FormInput v-model="product.physicality" type="text" class="w-full" :disabled="props.disabled" />
	</div>

	<div class="pt-4">
		<FormLabel class="font-medium">{{ $t('productDetails.contents') }}</FormLabel>
		<FormInput v-model="product.contents" type="text" class="w-full" :disabled="props.disabled" />
	</div>
</template>
