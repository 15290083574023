<script setup lang="ts">
import { computed, ref } from 'vue';
import { IsProductNote } from '../../interfaces/Products/is-product-note';
import { NumberUtils } from '../../../ts-modules/utils-module';
import Sort from '../Sort';
import { dateToString } from '../../../ts-modules/utils-module/utils/date-utils';

// Properties
interface productNotesConfig {
	productNotes: IsProductNote[];
}

const props = defineProps<productNotesConfig>();

// References
const gridHeaderRef = ref();
const gridRowsRef = ref();
let headerNames = ref<string[]>([]);
let productNotesList = ref(props.productNotes);
let unsortedProductNotes = ref(productNotesList);
let listKey = ref('productNotes');

// Computed
const productNoteSize = computed(() => {
	return props.productNotes?.length;
});

// Init logic
// Call getColumnNames when productNotes prop changes
updateHeaderNames();

/**
 * Update headerNames based on productNotes prop
 */
function updateHeaderNames(): void {
	if (props.productNotes && props.productNotes.length > 0) {
		headerNames.value = Object.getOwnPropertyNames(props.productNotes[0]).slice(1);
	} else {
		headerNames.value = [];
	}
}

function isEven(number: number): boolean {
	return NumberUtils.isEven(number);
}

function getNotesValueByPropertyName(productNote: any, propertyName: string): any {
	if (propertyName === 'lastModifiedDate') {
		return dateToString(new Date(productNote[propertyName]));
	} else {
		return productNote[propertyName];
	}
}
</script>

<template>
	<div class="box h-full break-words">
		<!-- BEGIN: Table head -->
		<div ref="gridHeaderRef" class="grid grid-cols-1 xl:grid-cols-2 p-3 pl-5 font-medium bg-slate-300 dark:bg-slate-900 rounded-t sticky z-50 top-0">
			<div class="xl:hidden">
				{{ $t('productNotes.mobileTitle') }}
			</div>

			<div v-for="header in headerNames" :key="header" class="hidden xl:block">
				<Sort
					class="select-none cursor-pointer"
					:list-key="listKey"
					:column-title="header"
					:unsorted-array="unsortedProductNotes"
					@sorted="productNotesList = $event"
				></Sort>
			</div>
		</div>
		<!-- END: Table head -->

		<!-- BEGIN: Table content -->
		<div ref="gridRowsRef">
			<div
				v-for="(productNote, index) in productNotesList"
				:key="productNote.pronkId"
				class="grid grid-cols-1 xl:grid-cols-2 p-3 pl-5"
				:class="[
					{ 'bg-white hover:bg-slate-300 dark:bg-slate-700 dark:hover:bg-slate-600': !isEven(index) },
					{ 'bg-secondary hover:bg-slate-300 dark:bg-slate-800 dark:hover:bg-slate-600': isEven(index) },
					{ 'rounded-b': productNoteSize === index }
				]"
			>
				<div v-for="header in headerNames" :key="header">
					<span class="xl:hidden">{{ $t(`productNotes.${header}`) }}:</span>
					{{ getNotesValueByPropertyName(productNote, header) }}
				</div>
			</div>
		</div>
		<!-- END: Table content -->
	</div>
</template>

<style scoped>
/* Disable number input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Disable number input arrows for Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
</style>
