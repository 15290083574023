import { IsHateoasResponse } from '../interfaces/hateoas/is-hateoas-response';
import { IsProduct } from '../interfaces/Products/is-product';
import { PiniaSingleton } from '../singletons/pinia-singleton';
import { useApiStore } from './api-store';
import { defineStore } from 'pinia';
import { WebStorage } from '../../ts-modules/storage-module';
import { Http } from '../../ts-modules/http-client-module';
import { copyWithoutReference } from '../../ts-modules/utils-module/utils/copy-utils';
import { useProductNotesStore } from './products-notes-store';
import { IsProductNote } from '../interfaces/Products/is-product-note';
import { IsFilterObject } from '../interfaces/Is-filter-object';

interface IsProductState {
	apiEndpoint: URL;
	defaultState: IsHateoasResponse;
	currentState: IsHateoasResponse;
	selectedProduct: IsProduct | undefined;
}

const storeId: string = 'products';
const defaultId: string = '_default';
const selectedId: string = '_selected';

const pinia = PiniaSingleton.getInstance();
const apiStore = useApiStore(pinia);
const api = apiStore.get;
const productNotesStore = useProductNotesStore(pinia);

export const useProductsStore = defineStore(storeId, {
	state: (): IsProductState => ({
		apiEndpoint: new URL(`${api}/${import.meta.env.VITE_PRODUCTS_ENDPOINT}`),
		defaultState: WebStorage.Default.getFromStorage(storeId + defaultId),
		currentState: WebStorage.Default.getFromStorage(storeId),
		selectedProduct: WebStorage.Default.getFromStorage(storeId + selectedId)
	}),
	getters: {
		getProducts(state) {
			if (state.currentState) {
				const embedded = state.currentState._embedded;
				if (embedded) {
					console.log('Embedded products:', embedded.products);
					return embedded.products ? embedded.products : [];
				}
			}
			return [];
		},
		getSelectedProduct(state): IsProduct | undefined {
			const selectedProduct = state.selectedProduct?.pronkId?.toString();

			if (selectedProduct) {
				const productNotesPromise = productNotesStore.getProductNotes(selectedProduct);
				productNotesPromise.then((productNotes: IsProductNote[]) => {
					state.selectedProduct!.productNotes = productNotes;
				});

				return state.selectedProduct;
			} else {
				return undefined;
			}
		},
		getOriginals(state): IsProduct[] {
			if (this.defaultState) {
				const embedded = state.defaultState._embedded;
				if (embedded) {
					return embedded.products ? embedded.products : [];
				}
			}
			return [];
		}
	},
	actions: {
		setProducts(products: IsProduct[]): void {
			if (this.currentState) {
				const embedded = this.currentState._embedded;
				if (embedded) {
					embedded.products = [...products];
				}
			}
		},

		setSelectedProduct(product: IsProduct | undefined): void {
			this.selectedProduct = product;
		},
		async productFilter(productFilter: IsFilterObject | null): Promise<IsProduct[]> {
			const url = new URL(`products/filter`, this.apiEndpoint.href);
			const response = await Http.post(url, productFilter);
			return response.body;
		},

		async createProduct(product: IsProduct): Promise<IsProduct> {
			const url = new URL(`products`, this.apiEndpoint.href);
			const response = await Http.post(url, product);
			return response.body;
		},

		async getFresh(): Promise<IsProduct[]> {
			await this.empty();
			await this.download();
			await this.cache();
			return this.getProducts;
		},
		async download() {
			try {
				const { body } = await Http.get(this.apiEndpoint);
				this.currentState = body;
				this.defaultState = copyWithoutReference(body);
			} catch (error) {
				throw error;
			}
		},
		async cache() {
			WebStorage.Default.saveToStorage(storeId, this.currentState);
			WebStorage.Default.saveToStorage(storeId + defaultId, this.currentState);
			if (this.selectedProduct) {
				WebStorage.Default.saveToStorage(storeId + selectedId, this.selectedProduct);
			}
		},
		async empty() {
			this.currentState = {};
			WebStorage.Default.removeFromStorage(storeId);
		}
	}
});
