<script setup lang="ts">
import { useInventoryStore } from '../../stores/inventory-store';
import PageTitle from '../../components/PageTitle';
import { IsChannel } from '../../interfaces/inventory/is-channel';
import { ref } from 'vue';
import { IsShop } from '../../interfaces/inventory/is-shop';

const inventoryStore = useInventoryStore();

const channels = ref<IsChannel[]>();
const shops = ref<IsShop[]>();

inventoryStore.downloadChannels().then(() => {
	channels.value = inventoryStore.getChannels;
	inventoryStore.downloadChannel(channels.value[0].key).then(channel => {
		console.log(channel);
	});
});

inventoryStore.downloadShops().then(() => {
	shops.value = inventoryStore.getShops;
	inventoryStore.downloadShop(shops.value[0].key).then(shop => {
		console.log(shop);
	});
});

inventoryStore.downloadAllocations(['abnamro', 'pronkjuweel']).then(res => {
	console.log(res);
});

inventoryStore.downloadShipmentsByChannel('pronkjuweel').then(res => {
	console.log(res);
});
</script>

<template>
	<div>
		<!-- BEGIN: Title row	-->
		<div class="flex flex-row">
			<div class="grow -intro-x">
				<PageTitle>{{ $t('inventoryPage.pageTitle') }}</PageTitle>
			</div>
		</div>
		<!-- END: Title row	-->

		<div class="flex flex-row">
			<div class="grow -intro-x">
				<pre>{{ channels }}</pre>
			</div>
		</div>
	</div>
</template>
