import { defineStore } from 'pinia';

interface AppConfigState {
	appNameValue: string;
	appAppLogoPath: any;
}

export const useAppConfigStore = defineStore('appConfig', {
	state: (): AppConfigState => ({
		appNameValue: 'Atlantis 2',
		appAppLogoPath: '/logo.svg'
	}),
	getters: {
		appName(state) {
			return state.appNameValue;
		},
		appLogoPath(state) {
			return state.appAppLogoPath;
		}
	}
});
