import { defineStore } from 'pinia';
import { IsFilter } from '../interfaces/is-filter';
import { WebStorage } from '../../../../ts-modules/storage-module';

interface filterState {
	filters: Map<string, IsFilter>;
}

const storeId: string = 'filterStore';

export const useFilterStore = defineStore(storeId, {
	state: (): filterState => ({
		filters: WebStorage.Default.getFromStorage(storeId) ? new Map<string, IsFilter>(WebStorage.Default.getFromStorage(storeId)) : new Map()
	}),
	actions: {
		getFilter(filterKey: string): IsFilter | undefined {
			const filter = this.filters.get(filterKey);
			return filter;
		},
		setFilter(filter: IsFilter): void {
			this.filters.set(filter.filterKey, filter);
			WebStorage.Default.saveToStorage(storeId, Array.from(this.filters.entries()));
		},
		deleteFilter(filterKey: string): void {
			this.filters.delete(filterKey);
			WebStorage.Default.removeFromStorage(filterKey);
		}
	}
});
