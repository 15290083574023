import { IsOrder } from '../interfaces/orders/is-order';
import { IsAddress } from '../interfaces/orders/is-address';

const getReceiverAddress = (order: IsOrder): IsAddress | undefined => {
	const receiver = order.addresses.find(x => x.type === 'RECEIVER');
	return receiver;
};

const getSenderAddress = (order: IsOrder): IsAddress | undefined => {
	const receiver = order.addresses.find(x => x.type === 'SENDER');
	return receiver;
};

export { getReceiverAddress, getSenderAddress };
